import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { useExport } from '../../../hooks/useExport';
import { SwitchComponent } from './SwitchComponent';
import Loader from '../../eunitblog/utils/loader/Loader';







const THead = styled.thead`
  font-weight: bold;
  padding: 1rem;
  width: 600px;
  height: 50px;
  left: 100px;
  top: 0px;
  background: f4f4f4;
`
const Th = styled.th`
  font-weight: bold;
`;

const IconContainer = styled.div`
  width: 18px;
  cursor: pointer;
  margin: auto auto;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
`;









export const MainTable = ({
  admins,
  setAdmins,
  adminsError,
  setAdminsError,
  handleDeleteAdmin

}) => {


  const {
    deleteIcon,
  } = useExport();

  if (!admins && adminsError === "") {
    return <Loader />
  } else if (!admins && adminsError) {
    return (
      <>
        <p className="errorMsg ml-5 p-5">{adminsError}</p>
      </>
    )
  };

  return (
    <>

      <Table striped bordered hover
        style={{
          textAlign: "center"
        }}
      >
        <THead>
          <tr
            style={{
              border: "0!important",
            }}
          >
            <Th
              style={{
                width: "10%",
                textAlign: "left"
              }}
            >
              Role
            </Th>

            <Th
              style={{
                width: "auto",
                textAlign: "left"
              }}
            >
              Username
            </Th>

            <Th
              style={{
                width: "auto",
                textAlign: "left"
              }}
            >
              Name
            </Th>

            <Th
              style={{
                width: "auto",
                textAlign: "left"
              }}
            >
              Email address
            </Th>

            <Th
              style={{
                width: "10%",
                textAlign: ""
              }}
            >
              Status
            </Th>
            <Th
              style={{
                width: "10%",
                textAlign: "center"
              }}
            >
              Delete
            </Th>
          </tr>
        </THead>
        <tbody>

          {
            admins?.map((admin, index) => (
              <tr
                style={{
                  border: "0!important",
                }}
                key={index}
              >
                <td
                  style={{
                    textAlign: "left"
                  }}
                >
                  {admin?.role === "admin" ? "Admin" : "Super admin"}
                </td>
                <td
                  style={{
                    textAlign: "left"
                  }}
                >
                  {admin?.email}
                </td>
                <td
                  style={{
                    textAlign: "left"
                  }}
                >
                  {admin?.userName}
                </td>
                <td
                  style={{
                    textAlign: "left"
                  }}
                >
                  {admin?.name}
                </td>
                <td>
                  <SwitchComponent
                    id={admin?.id}
                    status={admin?.status}
                  />
                </td>
                <td>
                  <IconContainer
                    onClick={(e) => handleDeleteAdmin(admin?.id, admin?.email)}
                  >
                    <Img
                      src={deleteIcon}
                      alt="Search"
                    />
                  </IconContainer>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

    </>
  )
}