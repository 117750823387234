import { useState, useEffect, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../../../navbar/Navbar';
import { useLocation } from "react-router-dom";
import BlogsCard from '../../../blog/components/blogs-cards';
import Footer from '../../../footer/Footer';
import { useLocationPathName } from '../../../../../hooks/useLocationPathname';




export default function AdminArchiveComponent() {


  const location = useLocation();

  const result = useLocationPathName(location);

  const [title, setTitle] = useState("Eunit | Blog");



  useEffect(() => {


    setTitle(`Eunit | Archive`);

  }, []);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);



  return (
    <>
      <Navbar />

      <Container>

        <p
          className="heading"
        >
          {result}
        </p>

      </Container>

      <div
        style={{
          position: "relative",
          top: "45vh"
        }}>

        <BlogsCard
          slug="blog"
        />

      </div>

      <Footer />

    </>
  )
}