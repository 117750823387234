import { useEffect, useState } from "react";
import styled from 'styled-components';
import { Colors } from "../../../../hooks/useColors";
import Title from '../../../eunitblog/utils/title/Title';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: center;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const P = styled.p`
  text-decoration: none;
  display: block;
  margin-top: 2rem;
`;

const ResetLinkSent = () => {

  let urlString = window.location.href;
  let paramString = urlString.split('?')[1];
  let queryString = new URLSearchParams(paramString);

  // iterate over all key-value pairs in the query string and select the first value of the pair

  for (let pair of queryString.entries()) {
    var getEmailAddress = pair[1];
  }


  const {
    primaryColor,
    tertiaryColor
  } = Colors();

  const [title, setTitle] = useState("Reset Link Sent | Eunit");

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);


  return (
    <Container>

      <Title
        textColor={primaryColor}
        text="Check Your Email"
        textAlign="center"
        style={{}}
      />


      <P
        style={{
          color: tertiaryColor,
          textDecoration: "none"
        }}
      >
        A password reset link has been sent to your email <b>{getEmailAddress}</b>
      </P>

    </Container>
  );
}


export default ResetLinkSent;