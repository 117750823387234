export const Colors = () => {

  const
    primaryColor = "#000000", // black
    primaryColorLight = "#333333", // gray
    secondaryColor = "#ffffff", // white
    whiteColor = "#ffffff"; // white

  return (
    {
      primaryColor,
      secondaryColor,
      whiteColor,
      primaryColorLight,
    }
  );
};