

export default function ContactDetails() {



  return (
    <>

      <div className="title-box-2 pt-4 pt-md-0">
        <div
          className="title-left"
          data-aos="fade-up"
        >
          Get in Touch
        </div>
      </div>
      <div className="more-info">
        <p className="lead"
          data-aos="fade-up"
        >
          Have something to say? We are always open to hearing it. Use any of these media to reach me.
        </p>
        <ul className="list-ico"
          data-aos="fade-up"
        >
          <li><span className="ion-ios-location"></span> Nigeria</li>
          <li><span className="ion-ios-telephone"></span>
            <a href="tel:+2348110863115">
              (+234)
              811-0863-115</a>
          </li>
          <li><span className="ion-email"></span> eunithelp@gmail.com
          </li>
        </ul>
      </div>
      <div className="socials"
        data-aos="fade-up"
      >
        <ul>
          <li>
            <a title="Facebook" href="https://facebook.com/eunit99">
              <span className="ico-circle">
                <i className="ion-social-facebook"></i>
              </span></a>
          </li>
          <li>
            <a title="Twitter" href="https://twitter.com/eunit99">
              <span className="ico-circle">
                <i className="ion-social-twitter"></i>
              </span>
            </a>
          </li>
          <li>
            <a title="LinkedIn" href="https://linkedin.com/in/eunit99">
              <span className="ico-circle">
                <i className="ion-social-linkedin"></i>
              </span>
            </a>
          </li>
          <li>
            <a title="GitHub" href="https://github.com/eunit99">
              <span className="ico-circle">
                <i className="ion-social-github"></i>
              </span>
            </a>
          </li>
          <li>
            <a title="Blog" href="https://eunit.hashnode.dev">
              <span className="ico-circle">
                <i className="ion-bookmark"></i>
              </span>
            </a>
          </li>
        </ul>
      </div>

    </>

  )
}