import styled from 'styled-components';
import { constants } from "./../../../../../../constants/constants";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Colors } from '../../../../../../hooks/useColors';
import ButtonComponent from '../../../../utils/button/ButtonComponent';









const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;

    @media (min-width: 1024px) {
      justify-content: space-around;
      align-items: center;
    }
`;

export default function Buttons({
  handlePreview,
  handlePublish,
  isWriting,
  handleUpdate,
  slug,
  isLoading
}) {

  const API_URL = constants.API_URL;
  const { id } = useParams();
  const blog_API_URL = `${API_URL}/${slug}s/${id}`;




  const {
    primaryColor,
    whiteColor,
    tertiaryColor
  } = Colors();



  const deleteDraft = () => {

    fetch(blog_API_URL, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
      body: "",
    }).then((data) => {
      console.log(data.statusText);
      toast.success(`Post deleted successfully.`)
    }).catch(err => {
      console.error('Error message: ', err)
      toast.error(`Error occurred. Could not delete post.`);
    });
  };




  return (
    <ButtonContainer>
      <ButtonComponent
        bgColor={whiteColor}
        text="Preview"
        style={{
          border: `2px solid ${primaryColor}`,
          margin: "auto 4px",
          color: tertiaryColor
        }}
        click={handlePreview}
        isLoading={isLoading}
      />

      {!isWriting ?
        <ButtonComponent
          bgColor={primaryColor}
          text="Update"
          style={{
            border: `2px solid ${primaryColor}`,
            margin: "auto 4px"
          }}
          click={handleUpdate}
          isLoading={isLoading}
        />
        :
        <ButtonComponent
          bgColor={primaryColor}
          text="Publish"
          style={{
            border: `2px solid ${primaryColor}`,
            margin: "auto 4px"
          }}
          click={handlePublish}
          isLoading={isLoading}
        />

      }

      {/* Show Publish button ONLY if slug === draft */}

      {
        slug === "draft" &&
        <>
          <ButtonComponent
            bgColor={primaryColor}
            text="Publish"
            style={{
              border: `2px solid ${primaryColor}`,
              margin: "auto 4px"
            }}
            click={(e) => {
              handlePublish(e);
              deleteDraft();
            }}
            isLoading={isLoading}
          />
        </>
      }

    </ButtonContainer>
  )
}