import { useEffect, useState } from "react";
import styled from 'styled-components';
import Title from '../../../eunitblog/utils/title/Title';
import SubTitle from '../../../eunitblog/utils/subtitle/SubTitle';
import { Link } from "react-router-dom";
import { Colors } from "../../../../hooks/useColors";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: center;
  text-align: center;
  max-width: 750px;
  margin: 3rem auto;
`;

const P = styled.p`
  text-decoration: none;
  display: block;
  margin-top: 2rem;
`;

const AccountCreationSuccessful = () => {

  const {
    primaryColor,
  } = Colors();

  const [title, setTitle] = useState("Account Creation Successful | Eunit");

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);


  return (
    <Container>

      <Title
        textColor={primaryColor}
        text="Your account has been successfully created."
        textAlign="center"
        style={{}}
      />

      <SubTitle
        textColor={primaryColor}
        text="We will review and activate your account before you may be able to interact with this platform"
        textAlign="center"
        style={{}}
      />

      <Link
        to="/blog"
        style={{
          textDecoration: "underline"
        }}
      >
        <P
          style={{
            color: primaryColor,
          }}
        >
          Go to Blog
        </P>
      </Link>

    </Container>
  );
}


export default AccountCreationSuccessful;