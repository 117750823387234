import { Container, Row, Col } from 'react-bootstrap';
import FormattingArea from './components/FormattingArea';
import TitleInputArea from './components/TitleInputArea';
import BodyInputArea from "./components/BodyInputArea";
import { Colors } from '../../../../../hooks/useColors';
import SubTitle from '../../../utils/subtitle/SubTitle';






export default function BlogTextArea({ blogTitle, blogBody, setBlogTitle, setBlogBody }) {


  const {
    primaryColor
  } = Colors();





  return (
    <>
      <section
        className="section write-blog-section"
        id="write-blog"
      >
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              lg={{ span: 6, order: 1 }}
            >

              <SubTitle
                textColor={primaryColor}
                text="Content"
                textAlign="left"
                style={{
                  fontWeight: "bold",
                  margin: "0"
                }}
              />

            </Col>

            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 2 }}
              lg={{ span: 6, order: 2 }}
            >
              <FormattingArea />

            </Col>

          </Row>

          <Row>
            <Col>
              <TitleInputArea
                blogTitle={blogTitle}
                setBlogTitle={setBlogTitle}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <BodyInputArea
                blogBody={blogBody}
                setBlogBody={setBlogBody}
              />
            </Col>
          </Row>

        </Container>

      </section>
    </>
  )
}