import React from 'react';
import './AboutSection.css';
import Profile from './profile/Profile';
import Text from './text/Text';


function AboutSection(props) {
	return (
		<>
			<section id="about" className="about-mf sect-pt4 route wow animate fadeInUp" data-wow-delay="1.2s">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="box-shadow-full">
								<div className="row">

									<Text />
									<Profile />

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default AboutSection;
