import React, { useEffect } from 'react';
import { useExport}  from '../../../hooks/useExport';
import ParticlesComponent from './ParticlesComponent';
import './PortfolioHero.css';
import TypeWriter from './TypeWriter';
import baffle from 'baffle';

function PortfolioHero(props) {

	useEffect(() => {
		const target = baffle('.intro-title');
		target.set({
			characters: "█▓ ▒░/ █░▓/ █▒▒▓/█ ░█ █░▓/ █▒▒▓/█ ░█ ▒/ ▒▓░ █<░▒ ▒/ ▒▓░ █<░▒ ▓/░>",
			speed: 100
		});
		target.start();
		target.reveal(5000, 100);
	});

	const { introBgImg } = useExport();
	const portfolioHeroStyles = { backgroundImage: `url(${introBgImg})` };

	return (
		<>
			{/* <!--/ Intro Skew Star /--> */}
			<div id="home" className="intro route bg-image" style={portfolioHeroStyles}>
				<div className="overlay-intro"></div>
				<div className="intro-content display-table">
					<div className="table-cell">
						<div className="container d-flex">
							<div className="name-container mb-3 text-center">
								<h1 className="intro-title mb-4">I am Emmanuel Uchenna
								</h1>

								{/* Type writer  */}
								<div className="intro-subtitle i">
									<span className="text-slider">
										<TypeWriter />
									</span>
								</div>
								{/* Type writer  */}

							</div>
						</div>
					</div>
				</div>
				<ParticlesComponent />
			</div>
			{/* <!--/ Intro Skew End /--> */}
		</>
	);
}

export default PortfolioHero;