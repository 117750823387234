import AuthCard from '../../../eunitblog/auth/AuthCard';
import FormComponent from './FormComponent';


const Right = () => {
  return (
    <AuthCard
      actionText="Attendee Survey"
      actionSubText="Please fill out this survey form"
    >
      <FormComponent>

      </FormComponent>
    </AuthCard>
  );
}

export default Right;