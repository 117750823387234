
import './Loader.css';


export default function Loader() {
  return (
   <div className="contain-loader">
      <div
        className="loader-container"
      >
        <div className="load">
        </div>
      </div>
   </div>
  )
}