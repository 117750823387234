
function Card({ iconName, nameOnCard, cardDesc }) {
  return (
    <>
      <div
        className="col-md-4"
        data-aos="fade-up"
      >
        <div
          className="service-box"
        >
          <div className="service-ico">
            <span className="ico-circle">
              {iconName}
            </span>
          </div>
          <div className="service-content">
            <h2 className="s-title">{nameOnCard} </h2>
            <p className="s-description text-center">
              {cardDesc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;