import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import SubmissionConfirmed from './SubmissionConfirmed';
import { Colors } from '../../../../hooks/useColors';




export default function Form() {

  const {
    primaryColor,
    whiteColor,
  } = Colors();



  const [state, handleSubmit] = useForm("xknawkad");
  if (state.succeeded) {
    return <>
      <SubmissionConfirmed
        bgColor={primaryColor}
        textColor={whiteColor}
      />
    </>
  }


  return (
    <>
      <div
        className="title-box-2"
        data-aos="fade-up"
      >
        <div className="title-left">
          Message Us
        </div>
      </div>
      <div>
        <form
          className="contactForm"
          target="_blank"
          rel="noreferrer noopener"
          onSubmit={handleSubmit}
        >

          <div id="sendmessage">Your message has been sent. Thank you!</div>

          <div id="errormessage"></div>

          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="form-group"
                data-aos="fade-up"
              >
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Your name"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars" required />

                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />

                <div className="validation"></div>

              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group"
                data-aos="fade-up"
              >
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Your email"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                  required

                />

                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />

                <div className="validation"></div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group"
                data-aos="fade-up"
              >
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"

                />

                <ValidationError
                  prefix="Subject"
                  field="subject"
                  errors={state.errors}
                />

                <div className="validation"></div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group"
                data-aos="fade-up"
              >
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"

                  data-msg="Please write something for us"
                  placeholder="Message"
                  required>

                </textarea>

                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />

                <div className="validation"></div>
              </div>
            </div>
            <div className="my-3" data-netlify-recaptcha="true"></div>
            <div className="col-md-12"
              data-aos="fade-up"
            >
              <button
                type="submit"
                className="button button-a button-big"
                disabled={state.submitting}
              >
                Send
                Message
              </button>
            </div>
          </div>
        </form>
      </div>


    </>
  )
}