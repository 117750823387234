import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useIcons } from "../../../../../hooks/useIcons";
import authService from '../../../../../services/auth.service';
import { Colors } from '../../../../../hooks/useColors';
import ButtonComponent from '../../../../eunitblog/utils/button/ButtonComponent';
import { Link, useNavigate } from 'react-router-dom';
















const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const FormContent = styled.div`

`;

const P = styled.p`
  cursor: pointer;
  text-decoration: none;
`;


export default function Right() {

  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherError, setOtherError] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setIsLoading(true);


    try {
      await authService.signup(data.email, data.password, "admin", data.userName, data.name).then(
        () => {
          // Successful signup
          setTimeout(() => {
            navigate("/account-successful");
          }, 2000);

        },
        (error) => {
          console.error(error);
          setOtherError(error?.message);
          setAuthError(error?.response?.data?.error);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);

  const {
    lightGrayColor,
    whiteColor,
    primaryColor,
  } = Colors();

  const {
    FaRegEye,
    FaRegEyeSlash,
  } = useIcons();

  return (
    <FormContainer
      style={{}}
    >
      <FormContent
        style={{
          backgroundColor: whiteColor,
          width: "100%",
          padding: "1rem",
          paddingTop: "0"
        }}
      >


        <Form>
          <Form.Group className="mb-3" controlId="">

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Username
            </Form.Label>

            <Form.Control
              className={`${errors.userName ? 'input-error' : ''}`}
              name="userName"
              {...register("userName", { required: "Please enter your username" })}
              type="text"
              autoComplete="off"
              placeholder="Enter username"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.userName && (
              <p className="errorMsg">{errors.userName.message}</p>
            )}

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Full name
            </Form.Label>

            <Form.Control
              className={`${errors.name ? 'input-error' : ''}`}
              name="name"
              {...register("name", { required: "Please enter your name" })}
              type="text"
              autoComplete="off"
              placeholder="Enter full name"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.name && (
              <p className="errorMsg">{errors.name.message}</p>
            )}

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Email address
            </Form.Label>

            <Form.Control
              className={`${errors.email ? 'input-error' : ''}`}
              name="email"
              {...register("email", { required: "Please enter email" })}
              type="text"
              autoComplete="off"
              placeholder="Enter email"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.email && (
              <p className="errorMsg">{errors.email.message}</p>
            )}

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Password
            </Form.Label>

            <Form.Control
              className={`${errors.password ? 'input-error' : ''}`}
              name="password"
              {...register("password", { required: "Please input password" })}
              type={seePassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Enter password"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {seePassword ?
              <FaRegEyeSlash
                className="auth-icon"
                onClick={() => setSeePassword(false)}
              />
              :
              <FaRegEye
                className="auth-icon"
                onClick={() => setSeePassword(true)}
              />}

            {errors.password && (
              <p className="errorMsg">{errors.password.message}</p>
            )}


          </Form.Group>

          <P
            style={{
              color: lightGrayColor,
              textDecoration: "none"
            }}
          >
            Already have an account?
            {" "}
            <Link
              to="/auth/sign-in"
              style={{
                textDecoration: "underline"
              }}
            >
              Signin
            </Link>
          </P>

        </Form>


        {authError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {authError}
          </p>
          )
        }

        {otherError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {otherError}
          </p>
          )
        }



        <ButtonComponent
          bgColor={primaryColor}
          text={!isLoading ? "Create account" : "Creating account ..."}
          style={{
            minWidth: "100%",
            margin: "2.125rem auto 1rem auto",
          }}
          click={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />

      </FormContent>
    </FormContainer>
  )
}