import React from 'react';
import SideBar from '../sidebar/SideBar';
import Articles from './articles/Articles';
import './Posts.css';

function Posts(props) {
	return (
		<>
			<section className="posts mb-4">
				<div className="container">
					<div className="row">
						<Articles />
						<SideBar />
					</div>
				</div>
			</section>
		</>
	);
}

export default Posts;