
const useDate = () => {

  var greet;
  var date = new Date();
  var hour = date.getHours();
  var year = date.getFullYear();

  if (hour >= 5 && hour <= 11) {
    greet = 'Good morning';

  } else if (hour === 12) {
    greet = 'Good noon';

  } else if (hour >= 13 && hour <= 17) {
    greet = 'Good afternoon';

  } else if (hour >= 18 && hour <= 4) {
    greet = 'Good evening';

  }

  return { greet, year, };
};

export default useDate;