import React from 'react';
import Footer from '../../../../footer/Footer';
import Navbar from '../../../../navbar/Navbar';
import SideBar from '../../../../sidebar/SideBar';
import Blog from './blog/Blog';

function SingleBlogWithSidebar(props) {
	return (
		<>
			<Navbar />
			{/* <!-- SingleBlogWithSidebar --> */}
			<section className="single-post mb-4">
				<div className="container">
					<div className="row mb-4">
						<Blog />

						<SideBar />
					</div>
				</div>
			</section>
			{/* <!-- SingleBlogWithSidebar --> */}
			<Footer />
		</>
	);
}

export default SingleBlogWithSidebar;