import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useExport}  from '../../../hooks/useExport';
import './PortfolioNav.css';
import { clsx } from 'clsx';
import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker';









function PortfolioNav(props) {

  const [scrolled, setScrolled] = useState(false);
  document.addEventListener("scroll", () => window.scrollY > 100 ? setScrolled(true) : setScrolled(false));

  const gaEventTracker = useAnalyticsEventTracker('Navigation links');
  const [isMobileNavShown, setIsMobileNavShown] = useState(false);

  const handleNavbarToggle = function () {
    setIsMobileNavShown(!isMobileNavShown);
  };


  const { Resume } = useExport();
  return (
    <>

      {/* <!--/ Nav Star /--> */}
      <nav className={`navbar navbar-b navbar-expand-md fixed-top ${scrolled ? " navbar-reduce" : "navbar-trans"}`} id="mainNav">
        <div className="container">
          <a className="navbar-brand js-scroll" href="#page-top">
            <span className="brand-e">E</span>
            <span className="brand-text">UNIT</span>
          </a>
          <button
            className={clsx("navbar-toggler", {
              "collapsed": !isMobileNavShown
            })}
            type="button"
            data-toggle="collapse" data-target="#navbarDefault"
            aria-controls="navbarDefault"
            aria-expanded={isMobileNavShown ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={handleNavbarToggle}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>


          <div
            id="navbarDefault"
            className={
              clsx("navbar-collapse collapse justify-content-end", {
                "show": isMobileNavShown
              })
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  onClick={() => gaEventTracker('About')}
                  className="nav-link js-scroll"
                  href="#about">About</a>
              </li>
              <li className="nav-item">
                <a
                  onClick={() => gaEventTracker('Services')}
                  className="nav-link js-scroll" href="#services">Services</a>
              </li>
              <li className="nav-item">
                <a
                  onClick={() => gaEventTracker('Project')}
                  className="nav-link js-scroll" href="#projects">Projects</a>
              </li>
              <li className="nav-item">
                <a
                  onClick={() => gaEventTracker('Contact')}
                  className="nav-link js-scroll"
                  href="#contact">Contact</a>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => gaEventTracker('Blog')}
                  className="nav-link js-scroll"
                  to="/soon">Blog</NavLink>
              </li>
              <li className="nav-item resume">
                <a
                onClick={() => gaEventTracker('Resume')}
                className="nav-link resume border"
                href={Resume}
                >Resume</a>
              </li>
            </ul>
          </div>

        </div>
      </nav>
      {/* <!--/ Nav End /--> */}
    </>
  );
}

export default PortfolioNav;