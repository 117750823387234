import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import { useExport } from "../../../../../hooks/useExport";
import Author from './components/Author';
import DateComponent from './components/DateComponent';
import ChooseFeaturedImage from './components/ChooseFeaturedImage';
import Buttons from './components/Buttons';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './BlogTools.css';
import { useLocationPathName } from "../../../../../hooks/useLocationPathname";
import { toast } from "react-toastify";
import { constants } from '../../../../../constants/constants';
import { Colors } from '../../../../../hooks/useColors';
import SubTitle from '../../../../eunitblog/utils/subtitle/SubTitle';
import authHeader from '../../../../../services/auth-header';








const ActionArea = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const DeleteContainer = styled.div`
  margin-left: 3.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
  margin-left: 3.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Img = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 0.425rem;
`;

const DeleteText = styled.div`
  color: #FF5151;
`;

const InputBorder = styled.div`
  border: 1px solid #848484;
  padding: 0;
  height: 56px;
  margin-bottom: 1.525rem;
  border-radius: 4px;
`;

const InputName = styled.p`
  color: #1c1c1c;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0.825rem;
`;





export default function BlogTools({
  author,
  featuredImage,
  date,
  handlePublish,
  handlePreview,
  setAuthor,
  setFeaturedImage,
  setDate,
  isWriting,
  handleUpdate,
  postError,
  slug,
}) {

  const API_URL = constants.API_URL;
  const { id } = useParams();
  const blog_API_URL = `${API_URL}/${slug}s/${id}`;
  const location = useLocation();
  const navigate = useNavigate();
  const result = useLocationPathName(location);





  const deleteBlog = () => {

    const headers = authHeader();

    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure you want to delete?`);

    if (confirmation === true) {

      fetch(blog_API_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body: "",
      }).then((data) => {
        console.log(data.statusText);
        if (data.status === 200) {
          toast.success("Deleted successfully!");
          setTimeout(() => {
            navigate(`/admin/${slug}`);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        } else {
          console.error("Did not delete");
          toast.error("Something went wrong!");
        };

      }).catch(err => {
        console.error('Error message: ', err)
      });


    } else {
      console.info("Delete aborted");
      toast.info("Delete aborted");
    }
  };


  const {
    primaryColor,
    dangerColor
  } = Colors();

  const { trashIcon } = useExport();






  return (
    <>

      <section
        className="section write-blog-section"
        id="write-blog"
      >
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
            >
              <ActionArea>

                <SubTitle
                  textColor={primaryColor}
                  text={`${result} Blog`}
                  textAlign="left"
                  style={{
                    fontWeight: "bold",
                    margin: "0"
                  }}
                />

                {/* Only show delete container if !isWriting */}
                {!isWriting &&
                  <DeleteContainer
                    onClick={deleteBlog}
                  >

                    <Img
                      src={trashIcon}
                      alt="Delete-icon"
                    />

                    <DeleteText>
                      Delete
                    </DeleteText>
                  </DeleteContainer>
                }

                <ErrorContainer>
                  {postError
                    &&
                    <SubTitle
                      textColor={dangerColor}
                      text={postError ? postError : `ERROR! Please fill out all input fields.`}
                      textAlign="right"
                      style={{
                        fontWeight: "bold",
                        margin: "0",
                        float: "right",
                      }}
                    />
                  }

                </ErrorContainer>

              </ActionArea>
            </Col>

          </Row>
        </Container>



        <Container
          className="mt-5"
        >
          <Row>

            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName>
                Author
              </InputName>
              <InputBorder>


                <Author
                  blogAuthor={author}
                  setBlogAuthor={setAuthor}
                />

              </InputBorder>

            </Col>

            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 2 }}
            >

              <InputName>
                Date
              </InputName>
              <InputBorder>
                <DateComponent
                  blogDate={date}
                  setBlogDate={setDate}
                />
              </InputBorder>

            </Col>

            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3 }}
            >

              <InputName>
                Choose featured image
              </InputName>
              <InputBorder>
                <ChooseFeaturedImage
                  featuredImage={featuredImage}
                  setFeaturedImage={setFeaturedImage}
                />
              </InputBorder>

            </Col>


            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >

              <InputName
                style={{
                  visibility: "hidden"
                }}
              >
                Buttons
              </InputName>
              <InputBorder
                style={{
                  border: "none",
                }}
              >
                <Buttons
                  handlePublish={handlePublish}
                  handlePreview={handlePreview}
                  isWriting={isWriting}
                  handleUpdate={handleUpdate}
                  slug={slug}
                />
              </InputBorder>

            </Col>


          </Row>
        </Container>

      </section>
    </>
  )
}