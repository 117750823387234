import React, { useEffect } from 'react';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';
import 'aos/dist/aos.css';
import LoadingState from './components/eunitblog/utils/loading/LoadingState';
import ReadyState from './components/eunitblog/utils/readystate/ReadyState';
import './App.css';



// TODO: Update TRACKING_ID to actual ID
// Start Google Analytics Code
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// End Google Analytics Code


function App() {

  useEffect(() => {
		AOS.init({
			delay: 100,
      once: true
		});
		AOS.refresh();
	}, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="homepage">
        <ReadyState />
        <LoadingState />
      </div>
    </>
  );
}

export default App;
