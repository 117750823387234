import React from 'react';

function Post(props) {
	return (
		<>
			<div className="card custom-card post p-2 mb-3">
				<div className="card-body">
					<div className="d-flex justify-content-between">
						<div className="writer d-flex align-items-center mb-3">
							<img src="/assets/img/avatar-2.jpg" className="img-writer rounded-circle me-2"
								alt="Alexa Jeffrey" />
							<a href="#" className="writer-name fw-bolder">Alexa Jeffrey</a>
						</div>
						<a role="button" data-bs-toggle="tooltip" data-bs-placement="top"
							title="Save"><i className="bi bi-bookmark"></i></a>
					</div>
					<div className="img-container">
						<a href="/single">
							<img src="/assets/img/post-4.jpg" alt="Post 4" className="mb-3 rounded-3"
								height="180px" />
						</a>
						<span className="post-tag">Music</span>
					</div>
					<a href="/single" className="fs-6 d-block text-dark fw-bolder">How Punk Became
						Punk?</a>
					<small className="text-secondary">June 18, 2021  •  13 min read</small>
				</div>
			</div>
		</>
	);
}

export default Post;