import React from 'react';

function Search(props) {
	return (
		<form className="d-none d-lg-block mb-4">
			<div className="input-group">
				<span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
				<input type="text" className="form-control" placeholder="Search" />
			</div>
		</form>
	);
}

export default Search;