import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SingleBlogWithSidebar from '../../posts/articles/article/singleblogwithsidebar/SingleArticleWithSidebar';
import About from '../../about/About';
import Contact from '../../contact/Contact';
import BlogHome from '../../BlogHome';
import AdminPanelPage from "../../pages/admin/admin-panel";
import SignInPage from '../../pages/auth/sign-in-page';
import ResetPasswordPage from '../../pages/auth/reset-password-page';
import CreateNewPasswordPage from '../../pages/auth/create-new-password-page';
import PasswordResetSuccessful from '../../auth/password-reset-successful';
import ResetLinkSent from '../../auth/reset-link-sent';
import SignUpPage from '../../pages/auth/sign-up-page';
import AdminBlogComponent from '../../pages/admin/blog';
import AdminDraftComponent from '../../pages/admin/draft';
import AdminArchiveComponent from '../../pages/admin/archive';
import WriteBlog from '../../pages/admin/write-blog';
import EditBlog from '../../pages/admin/edit-blog';
import SurveyPage from '../../pages/survey';
import ComingSoon from '../../blog/components/coming-soon';
import AccountCreationSuccessful from '../../auth/password-reset-successful';
import { ToastContainer } from 'react-toastify';
import Portfolio from '../../../portfolio/Portfolio';
import NotFound from '../../../notfound/NotFound';
import LaunchSoon from '../../../launch-soon';
import { ProtectedRoute, SuperAdminRoute } from '../../../../routes/ProtectedRoute';









function ReadyState(props) {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>

        <Route
          path="/auth/sign-in"
          element={<SignInPage />} />

        <Route
          path="/auth/reset-password"
          element={<ResetPasswordPage />} />

        <Route
          path="/auth/create-new-password/:userId/:authToken"
          element={<CreateNewPasswordPage />} />

        <Route
          path="/auth/create-new-password/"
          element={
            <NotFound
              error="Invalid or no auth token provided"
            />
          }
        />

        <Route
          path="/auth/password-reset-successful"
          element={<PasswordResetSuccessful />} />

        <Route
          path="/account-successful"
          element={<AccountCreationSuccessful />} />

        <Route
          path="/auth/reset-link-sent"
          element={<ResetLinkSent />} />

        <Route
          path="/auth/"
          element={<SignInPage />} />

        <Route exact path="/auth/sign-up" element={<SignUpPage />} />

        <Route exact path="/admin" element={<SuperAdminRoute />}>
          <Route exact path="/admin" element={<AdminPanelPage />} />
        </Route>

        <Route exact path="/admin/" element={<SuperAdminRoute />}>
          <Route exact path="/admin/" element={<AdminPanelPage />} />
        </Route>

        <Route exact path="/admin/blog" element={<ProtectedRoute />}>
          <Route exact path="/admin/blog" element={<AdminBlogComponent />} />
        </Route>

        <Route exact path="/admin/draft" element={<ProtectedRoute />}>
          <Route exact path="/admin/draft" element={<AdminDraftComponent />} />
        </Route>

        <Route exact path="/admin/archive" element={<ProtectedRoute />}>
          <Route exact path="/admin/archive" element={<AdminArchiveComponent />} />
        </Route>

        <Route exact path="/admin/write" element={<ProtectedRoute />}>
          <Route exact path="/admin/write" element={<WriteBlog />} />
        </Route>

        <Route exact path="/admin/edit/draft/:id" element={<ProtectedRoute />}>
          <Route exact path="/admin/edit/draft/:id" element={<EditBlog />} />
        </Route>

        <Route exact path="/admin/edit/blog/:id" element={<ProtectedRoute />}>
          <Route exact path="/admin/edit/blog/:id" element={<EditBlog />} />
        </Route>

        <Route exact path="/admin/" element={<ProtectedRoute />}>
          <Route exact path="/admin/" element={<AdminBlogComponent />} />
        </Route>

        <Route exact path="/admin/*" element={<ProtectedRoute />}>
          <Route exact path="/admin/*" element={<AdminBlogComponent />} />
        </Route>

        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<BlogHome />} />
        <Route path="/survey" element={<SurveyPage />} />

        <Route path="/single/:id" element={<SingleBlogWithSidebar />} />
        <Route path="/LaunchSoon" element={<LaunchSoon />} />
        <Route exact path="/" element={<Portfolio />} />


        <Route exact path="/soon" element={<ComingSoon />} />
        <Route path="*" element={<NotFound />} />




      </Routes>
    </>
  );
}

export default ReadyState;