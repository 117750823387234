import React from 'react';
import Newsletter from './newsletter/Newsletter';
import PopularPosts from '../sidebar/popularPosts/PopularPosts';
import PopularTopics from '../sidebar/popularTopics/PopularTopics';
import './SideBar.css';
import Search from './search/Search';

function SideBar(props) {
	return (
		<>
			<div className="col-lg-4">
				<Search />
				<Newsletter />
				<PopularPosts />
				<PopularTopics />
			</div>
		</>
	);
}

export default SideBar;