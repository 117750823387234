import React from "react";
import Card from "./components/Card";



export default function PortfolioArticles() {
  return (
    <>
      {/* <!--/ Section Portfolio Start /--> */}
      <section id="portfolio-articles" className="portfolio-mf sect-pt4 route wow animate fadeInUp" data-wow-delay="1.2s">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
              className="title-box text-center"
                data-aos="fade-up"
              >
                <div className="title-a">
                  Technical Articles
                </div>
                <p className="subtitle-a">
                 Featured technical articles.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>


          <div className="row">
            {/* Work card begins */}
            <Card />
            {/* Work card begins */}
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col offset-5 offset-md-8 offset-lg-10">
              <div className="see-more">
                <a href="https://bit.ly/3JJmmCE" target="_blank" rel="noopener noreferrer">
                  <div className="btn see-more-btn button button-a">
                    See more articles →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ Section Portfolio End /--> */}
    </>
  )
}