import RandomQuote from "./randomquotes/RandomQuote";

function Profile(props) {
  return (
    <>
      <div className="col-md-6">
        <div className="about-me pt-4 pt-md-0">
          <div className="title-box-2">
            <div
              className="title-left"
              data-aos="fade-up"
            >
              About me
            </div>
          </div>
          <p
            className="lead"
            data-aos="fade-up"
          >
            Emmanuel Uchenna is an experienced, passionate, and enthusiastic software developer and technical writer with proven years of professional experience. He does fullstack web development. Emmanuel Uchenna is fluent with ReactJS, JavaScript, VueJS, NodeJS, among other web development libraries.
          </p>

          <p
            className="lead"
            data-aos="fade-up"
          >
            He is conversant with industry standard technologies such as Git & GitHub, TDD. He helps individuals, businesses, and brands build their online presence by helping them develop responsive, accessible, beautiful, and intuitive websites. He also does technical writings where he writes for different websites and himself.
          </p>

          <RandomQuote />

        </div>
      </div>
    </>
  );
}

export default Profile;