import React from 'react';
import { FiMonitor } from 'react-icons/fi';
import { MdOutlineScreenRotation } from 'react-icons/md';
import { BsCodeSlash, BsCamera } from 'react-icons/bs';
import { AiOutlineFormatPainter } from 'react-icons/ai';
import { GrArticle } from 'react-icons/gr'
import Card from './card/Card';






function Services(props) {
  return (
    <>

      {/* <!--/ Section Services Star /--> */}
      <section id="services" className="services-mf route wow animate fadeInUp" data-wow-delay="1.2s">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="title-box text-center"
                data-aos="fade-up"
              >
                <div className="title-a">
                  Services
                </div>
                <p className="subtitle-a">
                  What do I offer?
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>

          {/* Cards  */}
          <div className="row">

            <Card
              iconName={<FiMonitor />}
              cardDesc="Web design"
              nameOnCard="Designs with a difference"
            />
            <Card
              cardDesc="Software development"
              nameOnCard="Got an idea? Let me make it a reality."
              iconName={<BsCodeSlash />}
            />
            <Card
              cardDesc="Photography"
              nameOnCard="From passion to professionalism."
              iconName={<BsCamera />}
            />
            <Card
              cardDesc="Responsive design"
              nameOnCard="Great designs across all devices."
              iconName={<MdOutlineScreenRotation />}
            />
            <Card
              cardDesc="Graphic design"
              nameOnCard="I create attractive graphic designs."
              iconName={<AiOutlineFormatPainter />}
            />
            <Card
              cardDesc="Technical writing"
              nameOnCard="Professional technical writer"
              iconName={<GrArticle />}
            />

            {/* Cards  */}

          </div>
        </div>
      </section>
    </>
  );
}

export default Services;