import { Fragment } from 'react';
import styled from 'styled-components';


const Ul = styled.ul`
  list-style: none;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 12px;

  &&::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Li = styled.li`
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  margin-right: 12px;
  margin-bottom: 4px;
`;



export default function Card({ data }) {


  return (
    <>
      {
        data?.map((work) => (
          <Fragment
            key={work.id}
          >
            <div
              className="col-md-4"
              data-aos="fade-up"
            >
              <div
                className="work-box works">
                <a
                  href={work.url}
                  target="_blank"
                  rel="noreferrer noopener">
                  <div
                    className="work-img">
                    <img
                      src={work.img}
                      alt={work.title}
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </a>
                <div
                  className="work-content">
                  <div
                    className="row">
                    <div className="col-sm-12">
                      <a href={work.url}
                        target="_blank"
                        rel="noreferrer noopener">
                        <h2 className="w-title text-colored bold">{work.title}</h2>
                      </a>
                      <div className="w-more">
                        <span className="w-category">
                          {work.description}
                        </span> / <span className="w-date">
                          {work.date}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row">
                    <div
                      className="col-sm-12 mt-3">
                      <p
                        className="b"
                        style={{}}
                      >
                        Technologies
                      </p>

                      <Ul>
                        {work.technologies.map((technology) => (
                          <Li
                            className="mr-2 p-1"
                            key={technology}
                          >
                            {technology}
                          </Li>
                        ))}
                      </Ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ))
      }
    </>
  )
}