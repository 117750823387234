import React, { useEffect, useState } from "react";
import Card from "./components/Card";
// import localWork from "./data/data.json";
import axios from "axios";
import { clsx } from 'clsx';



export default function PortfolioWork() {

  const [works, setWorks] = useState([]);
  const [selectedWorkData, setSelectedWorkData] = useState("all")
  const [isPending, setIsPending] = useState(true);


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_FIREBASE_API_URL}/works.json`)
      .then(res => {
        const result = res.data;
        setWorks(result);
        setIsPending(false);
      })
  }, [])


  const [filteredWorks, setFilteredWorks] = useState(works)


  const handleChangeSelected = (term) => {
    setSelectedWorkData(term);
  }

  useEffect(() => {

    if (selectedWorkData === "all") {
      setFilteredWorks(works);
    } else {
      setFilteredWorks(
        works.filter(work => work.where === selectedWorkData)
      )
    }
  }, [selectedWorkData, works])



  return (
    <>
      {/* <!--/ Section Portfolio Start /--> */}
      <section id="projects" className="portfolio-mf sect-pt4 route wow animate fadeInUp" data-wow-delay="1.2s">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="title-box text-center"
                data-aos="fade-up"
              >
                <div className="title-a">
                  Projects
                </div>
                <p className="subtitle-a">
                  Some projects I worked on.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>

          <div className="filter-wrapper">
            <div
              className={
                clsx("btn button", { "button-active": selectedWorkData === "all" })
              }
              onClick={() => handleChangeSelected("all")}
            >
              All
            </div>

            <div className={
              clsx("btn button", { "button-active": selectedWorkData === "personal" })
            }
              onClick={() => handleChangeSelected("personal")}
            >
              Personal
            </div>

            <div className={
              clsx("btn button", { "button-active": selectedWorkData === "freelance" })
            }
              onClick={() => handleChangeSelected("freelance")}
            >
              Freelance
            </div>

            <div className={
              clsx("btn button", { "button-active": selectedWorkData === "bacf" })
            }
              onClick={() => handleChangeSelected("bacf")}
            >
              At BACF, Inc.
            </div>
          </div>

          <div className="row">
            {/* Work card begins */}
            {
              !isPending &&
              filteredWorks && (
                <Card
                  data={filteredWorks}
                />
              )
            }
            {/* Work card begins */}
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col offset-5 offset-md-8 offset-lg-10">
              <div className="see-more">
                <a href="https://github.com/Eunit99?tab=repositories" target="_blank" rel="noopener noreferrer">
                  <div className="btn see-more-btn button button-a">
                    See more projects &#8599;
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ Section Portfolio End /--> */}
    </>
  )
}