import { toast } from "react-toastify";
import authService from "../services/auth.service";







export const reducer = (state, action) => {
  switch (action.type) {
    case "GET_STARTED":
      return {

      };
    case "SIGN_UP_TO_NEWSLETTER":
      return {
        count: state.count + 1,
        showText: !state.showText
      };
    default:
      return state;
  }
};

export const redirectToGetStarted = (e) => {
  const redirectToGetStartedURL = "/get-started";
  e.preventDefault();
  window.location.href = redirectToGetStartedURL;
};

export const redirectToBlog = (e) => {
  const redirectToBlogURL = "/blog";
  e.preventDefault();
  window.location.href = redirectToBlogURL;
};

export const authRedirection = () => {
  authService.logout();

  const authRedirectionURL = "/auth";
  window.location.href = authRedirectionURL;

};

export const adminRedirection = () => {
  const adminRedirectionURL = "/admin/blog";
  window.location.href = adminRedirectionURL;
};

export const superAdminRedirection = () => {
  const superAdminRedirectionURL = "/admin";
  window.location.href = superAdminRedirectionURL;
};

export const redirectToResetLinkSent = (userEmail) => {
  const redirectToResetLinkSentURL = `/auth/reset-link-sent?email=${userEmail}`;
  window.location.href = redirectToResetLinkSentURL;
};

export const inactiveUserAction = () => {
  // Clear localStorage details
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("isAuthenticated");
  toast.error("Sorry, your account is disabled! Please contact administrator.");
}