import React from 'react';

function ArticleHero(props) {
	return (
		<>
			<div className="col-md-12 col-lg-12 mb-3">
				<div className="card custom-card post h-100 p-2">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<div className="writer d-flex align-items-center mb-3">
								<img src="/assets/img/avatar-1.jpg" className="img-writer rounded-circle me-2"
									alt="John Doe" />
								<a href="#" className="writer-name fw-bolder">John Doe</a>
							</div>
							<a role="button"><i className="bi bi-bookmark" data-bs-toggle="tooltip"
								data-bs-placement="top" title="Save"></i></a>
						</div>
						<div className="img-container">
							<a href="/single">
								<img src="/assets/img/post-1.jpg" alt="Post 1" className="mb-3 rounded-3"
									height="200px" />
							</a>
							<span className="post-tag">Design</span>
						</div>
						<a href="/single" className="fs-6 d-block text-dark fw-bolder">17 Inspiring
							Examples
							of
							Beautiful Blog
							Homepage
							Design</a>
						<small className="text-secondary">June 26, 2021  •  2 min read</small>
					</div>
				</div>
			</div>
		</>
	);
}

export default ArticleHero;