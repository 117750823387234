import './Testimonial.css';
import bd from './assets/overlay-bg.jpg'
import testimonial4 from './assets/testimonial-4.png'
import { useIcons }  from '../../../hooks/useIcons';












export default function Testimonial() {

  const {
    FaQuoteRight
  } = useIcons();

  return (
    <>

      {/*  Section Testimonials Start */}
      <div id="testimonial"
        className="testimonials paralax-mf bg-image wow
            animate fadeInUp"
        data-wow-delay="1.2s"
        style={{
          backgroundImage: `url(${bd})`,
        }}
      >
        <div className="overlay-mf"></div>
        <div className="container"
          style={{
            position: "relative",
          }}>
          <div className="row">
            <div
              className="col-sm-12"
              data-aos="fade-up"
            >
              <div className="title-box text-center">
                <div className="title-a text-white">
                  Testimonial
                </div>
                <p className="subtitle-a text-white">
                  What clients are saying.
                </p>
                <div className="line-mf-white"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                id="testimonial-mf"
                className=""
                style={{
                  display: "block"
                }}
              >
                <div className="testimonial-box">
                  <div
                    className="author-test"
                    data-aos="fade-up"
                  >
                    <img src={testimonial4} alt="Kim Linton" className="rounded-circle b-shadow-a" />
                    <span className="author">Kim Linton</span><span className="work lead">Agile Consultant at <a target="_blank"
                      rel="noreferrer noopener" className="text-colored font-weight-bold" href="https://weare1light.com">1
                      Light</a></span>
                  </div>
                  <div
                    className="content-test"
                    data-aos="fade-up"
                  >
                    <p
                      className="description lead"
                      data-aos="fade-up"
                    >
                      Emmanuel is a teammate you can trust on every level. He is a self-organizing, agile leader who
                      understands people and how to collaborate thoughtfully. Technically an expert, and great with people
                      -- the perfect balance of both worlds. His accountability during our 100% remote work/pandemic
                      situation was incredible. I would want Emmanuel on my team if I were you!!
                    </p>
                    <span className="commit">
                      <FaQuoteRight />

                      <i className="fa fa-quote-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section Testimonials End */}
    </>
  )
}