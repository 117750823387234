import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from "styled-components";



const TitleInputAreaContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  justify-content: start;
`;

const Title = styled.div`
  max-width: 70px;
  border-right: 1px solid #696969;
  color: #696969;
  padding: 1rem;
  font-size: 1rem;
  margin-right: 1rem;
`;


export default function TitleInputArea({ blogTitle, setBlogTitle }) {



  const { register, formState: { errors }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setBlogTitle(data.title);
    console.log(`title: ${blogTitle}`);
  };


  return (
    <>
      <TitleInputAreaContainer>
        <Title>
          Title
        </Title>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          onChange={handleSubmit(onSubmit)}
        >
          <Form.Control
            className={`blog-tools-form__input ${errors.title ? '' : ''}`}
            name="title"
            {...register("title", { required: "Please enter blog title" })}
            type="text"
            autoComplete="on"
            placeholder="Enter Blog Title"
            style={{
              backgroundColor: "",
              padding: "12px 28px",
              fontWeight: "500",
              minHeight: "100px",
              minWidth: "30vmax",
              fontFamily: "Poppins",
            }}
            defaultValue={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
          />

          {errors.username && (
            <p className="errorMsg">{errors.title.message}</p>
          )}

        </Form>
      </TitleInputAreaContainer>
    </>
  )
}