



export default function SubmissionConfirmed({
  bgColor,
  textColor
}) {
  return (
    <>
      <div
        className="banner"
        data-aos="fade-up"
        style={{
          backgroundColor: bgColor,
        }}
      >
        <h4
          data-aos="fade-up"
          style={{
            color: textColor,
          }}
        >
          We have received your message. We will get in touch!</h4>

        <br />
        <br />

        <h1
          data-aos="fade-up"
          style={{
            color: textColor,
          }}
        >Thank you!</h1>
      </div>
    </>
  )
}