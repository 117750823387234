import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import authService from "../services/auth.service";






export const ProtectedRoute = () => {
  const isAuthenticated = authService.isAuthenticated();


  return isAuthenticated ? <Outlet /> : <Navigate to="/auth" />;
}



export const SuperAdminRoute = () => {
  const currentUser = authService.currentUser();


  return currentUser?.role === "super_admin" ? <Outlet /> : <Navigate to="/admin/blog" />;
}
