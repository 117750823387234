import React from 'react';
import './Newsletter.css';
function Newsletter(props) {
	return (
		<div className="card custom-card p-3 mb-4 newsletter">
			<div className="card-body">
				<h4 className="fw-bolder">Newsletter</h4>
				<p>Get all latest content delivered straight to your inbox.</p>
				<form action="">
					<input type="text" className="form-control mb-3" placeholder="Enter your email address" />
					<div className="d-grid gap-2">
						<button type="submit" className="btn btn-outline-dark no-border-radius">Subscribe</button>
					</div>
				</form>
			</div>
			<a role="button" className="close-button fs-2"><i
				className="bi bi-x"></i></a>
		</div>
	);
}

export default Newsletter;