import React from 'react';

function LoadingState(props) {
  return (
    <div>

    </div>
  );
}

export default LoadingState;