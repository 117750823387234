/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useDate from '../../../hooks/useDate';
import './Footer.css';

function Footer(props) {
	const { year } = useDate();
	return (
		<>
			{/* <!-- Footer --> */}
			<footer className="bg-light">
				<div className="container">
					<div className="row">
						<div className="col">
							<h1 className="fw-bolder logo">
								<span className="brand-e">E</span>
								<span className="brand-text">unit Blog</span>
							</h1>
							<hr />
							<ul className="links list-unstyled mb-5">
								<li className="text-decoration-none mb-2">
									<a href="">About</a>
								</li>
								<li className="text-decoration-none mb-2">
									<a href="">Privacy Policy</a>
								</li>
								<li className="text-decoration-none mb-2">
									<a href="">Disclaimer</a>
								</li>
								<li className="text-decoration-none mb-2">
									<a href="">Contact</a>
								</li>
								<li className="text-decoration-none mb-2">
									<a href="">RSS</a>
								</li>
							</ul>
							<span className="text-secondary">&copy; {year} &nbsp;
								<span className="brand-e">E</span>
								<span className="brand-text">unit</span>
							</span>
						</div>
					</div>
				</div>
			</footer>
			{/* <!-- End of Footer --> */}
		</>
	);
}

export default Footer;