import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTruncate } from "./../../../../hooks/useTruncate";



const Ul = styled.ul`
  list-style: none;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 12px;

  &&::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Li = styled.li`
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  margin-right: 12px;
  margin-bottom: 4px;
`;



export default function Card() {

  const [articles, setArticles] = useState("");
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_FIREBASE_API_URL}/articles.json`)
      .then(res => {
        const result = res.data;
        setArticles(result);
        setIsPending(false);
      })
  }, [])


  return (
    <>
      {
        !isPending && articles.map((article) => (
          <Fragment
            key={article.id}
          >
            <div
              className="col-md-4"
              data-aos="fade-up"
            >
              <div
                className="work-box articles">
                <a
                  href={article.url}
                  target="_blank"
                  rel="noreferrer noopener">
                  <div
                    className="work-img">
                    <img
                      src={article.img}
                      alt={article.title}
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </a>
                <div
                  className="work-content mt-4">
                  <div
                    className="row">
                    <div className="col-sm-12">
                      <a href={article.url}
                        target="_blank"
                        rel="noreferrer noopener">
                        <h2 className="w-title text-colored bold my-3">{article.title}</h2>
                      </a>

                      <div className="w-more">
                        <div className="w-category">

                          {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                          {useTruncate(article.summary, 300)}
                        </div>
                        <div className="w-date mt-3">
                          {article.date}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row">
                    <div
                      className="col-sm-12 mt-3">
                      <p
                        className="b"
                        style={{}}
                      >
                        Tags
                      </p>

                      <Ul>
                        {article.tags.map((tag) => (
                          <Li
                            className="mr-2 p-1 border"
                            key={tag}
                          >
                            {tag}
                          </Li>
                        ))}
                      </Ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ))
      }
    </>
  )
}