import Survey from "../../../survey-form/form";



export default function SurveyPage() {
  return (
    <>
      <Survey />
    </>
  )
}