import styled from 'styled-components';


const Button = styled.button`
  background-color: #b7b5b56e;
  color: #727272;
  border: 0;
  border-radius: 50%;
  padding: 6px 12px;
  ${'' /* position: absolute; */}
  top: 2%;
  right: 2%;
  outline: none;

     :hover {
        background-color: #0000006e;
        color: #000000;
        font-weight: bold;
      }
`;


export default function CloseButton({
  handleClick
}) {


  return (
    <Button
      onClick={handleClick}
      title="Close"
    >
      X
    </Button>
  )
}