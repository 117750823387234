import useDate from "../../../hooks/useDate";
import './Footer.css'






export default function Footer() {

const {
  year
} = useDate();


  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box fz-10">
                <p className="color-text-a"> &copy; <span className="copyright" id="copyright">{year}</span> Ξunit Designs. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--/ Section Contact-footer End /--> */}

      <a aria-label="Scroll to top" href="#mainNav" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
      {/* <!-- <div id="preloader"></div> --> */}
    </>
  )
}