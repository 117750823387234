import React, { useState } from 'react';
import { quotes } from './quotes';
import { authors } from './quotes';

const RandomQuote = () => {
  const numberOfQuotes = quotes.length;


  const [favouriteCount, setFavouriteCount] = useState(0);
  const [currentQuote, setCurrentQuote] = useState("You are what you look at. Look at the good things around you.");
  const [currentQuoteAuthor, setCurrentQuoteAuthor] = useState("Eunit");

  const handleNextQuote = () => {
    const randomNumber = Math.floor(Math.random() * numberOfQuotes);
    setCurrentQuoteAuthor(authors[randomNumber]);
    setCurrentQuote(quotes[randomNumber]);
  };

  const handleFavouriteClick = () => {
    setFavouriteCount(favouriteCount + 1);
  };

  return (
    <div
      className="content-wrapper"
      data-aos="fade-up"
    >
      <div className="quote-container">
        <div className="quote-title title-left">Random quote</div>
        <div id="quote-wrapper" className="quote-wrapper">

          {/* <!-- Quote content goes here --> */}
          <div className="quote-content" id="quote-content">
            {currentQuote}
          </div>
          <div className="quote-author">
            —
            <span id="quote-author">
              {currentQuoteAuthor}
            </span>
          </div>

          {/* <!-- Quote contents end --> */}

          <div className="heart">
            <span
              onClick={handleFavouriteClick}
              className="heart-plus"
              title={`${favouriteCount} favourites`}
            > ❤ {favouriteCount} </span> <span className="heart-cont"></span>
          </div>
          <div className="new-quote--container">
            <div
              onClick={handleNextQuote}
              className="new-quote">NEW QUOTE</div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default RandomQuote;