import { ImHtmlFive2 } from 'react-icons/im';
import { TiCss3 } from 'react-icons/ti';
import { IoLogoJavascript, IoLogoSass, IoLogoPython, IoLogoNodejs } from 'react-icons/io';
import { SiJquery, SiReact, SiTailwindcss, SiFirebase, SiWordpress, SiFlask, SiVisualstudiocode, SiPostgresql, SiHeroku, SiTypescript } from 'react-icons/si';
import { BsBootstrap } from 'react-icons/bs';
import { AiOutlineGithub } from 'react-icons/ai';
import { DiGit } from 'react-icons/di';
import { FiTrello } from 'react-icons/fi';
import { FaVuejs, FaSquarespace, FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { FaQuoteRight } from 'react-icons/fa';






export const  useIcons = () => {
	return (
		{
			HTML5: ImHtmlFive2,
			CSS3: TiCss3,
			JS: IoLogoJavascript,
      TS: SiTypescript,
			JQuery: SiJquery,
			BS: BsBootstrap,
			React: SiReact,
			Git: DiGit,
			GitHub: AiOutlineGithub,
			SASS: IoLogoSass,
			Tailwind: SiTailwindcss,
			Python: IoLogoPython,
			Firebase: SiFirebase,
			Flask: SiFlask,
			Wordpress: SiWordpress,
			VSCode: SiVisualstudiocode,
			NodeJS: IoLogoNodejs,
			VueJS: FaVuejs,
			Postgresql: SiPostgresql,
			Heroku: SiHeroku,
			Squarespace:FaSquarespace,
			Trello: FiTrello,
      FaQuoteRight,
      FaRegEyeSlash,
      FaRegEye,
		}
	);
}