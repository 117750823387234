import { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import authService from '../../../../../services/auth.service';
import { Colors } from '../../../../../hooks/useColors';
import ButtonComponent from '../../../../eunitblog/utils/button/ButtonComponent';
import { useIcons } from "../../../../../hooks/useIcons";











const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const FormContent = styled.div`

`;

const P = styled.p`
  cursor: pointer;
  text-decoration: none;
`;

export default function Right() {

  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherError, setOtherError] = useState("");
  const [seePassword, setSeePassword] = useState(false);

  const { register, formState: { errors }, handleSubmit } = useForm();



  const onSubmit = async (data) => {
    // console.log(data);
    setIsLoading(true);


    try {
      await authService.login(data.username, data.password).then(
        () => {
          // Successful login
        },
        (error) => {
          console.error(error);
          setOtherError(error?.message);
          setAuthError(error?.response?.data?.error);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);



  const {
    lightGrayColor,
    whiteColor,
    primaryColor,
  } = Colors();

  const {
    FaRegEye,
    FaRegEyeSlash,
  } = useIcons();

  return (
    <FormContainer
      style={{}}
    >
      <FormContent
        style={{
          backgroundColor: whiteColor,
          width: "100%",
          padding: "1rem",
          paddingTop: "0"
        }}
      >


        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Username
            </Form.Label>

            <Form.Control
              className={`${errors.username ? "input-error" : ""}`}
              name="username"
              {...register("username", { required: "Please enter your username" })}
              type="text"
              autoComplete="off"
              placeholder="Enter your username"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.username && (
              <p className="errorMsg">{errors.username.message}</p>
            )}

            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Password
            </Form.Label>

            <Form.Control
              className={`${errors.password ? "input-error" : ""}`}
              name="password"
              {...register("password", { required: "Please input your password" })}
              type={seePassword ? "text" : "password"}
              autoComplete="off"
              placeholder="Enter your password"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {seePassword ?
              <FaRegEyeSlash
                className="auth-icon"
                onClick={() => setSeePassword(false)}
              />
              :
              <FaRegEye
                className="auth-icon"
                onClick={() => setSeePassword(true)}
              />}

            {errors.password && (
              <p className="errorMsg">{errors.password.message}</p>
            )}

          </Form.Group>


          <Link
            to="/auth/reset-password"
            style={{
              textDecoration: "none"
            }}
          >
            <P
              style={{
                color: lightGrayColor,
                textDecoration: "underline"
              }}
            >
              Forgot password?
            </P>

          </Link>

          <P
            style={{
              color: lightGrayColor,
              textDecoration: "none"
            }}
          >
            Don't have an account?
            {" "}
            <Link
              to="/auth/sign-up"
              style={{
                textDecoration: "underline"
              }}
            >
              Signup
            </Link>
          </P>

        </Form>

        {authError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {authError}
          </p>
          )
        }

        {otherError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {otherError}
          </p>
          )
        }



        <ButtonComponent
          bgColor={primaryColor}
          text={!isLoading ? "Sign In" : "Signing in ..."}
          style={{
            minWidth: "100%",
            margin: "2.125rem auto 1rem auto",
          }}
          click={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />

      </FormContent>
    </FormContainer>
  )
}