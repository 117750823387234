import styled from 'styled-components';










const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;


export default function Right() {

  return (
    <FormContainer
      style={{}}
    >
      <iframe
        title="Eunit attendee survey form"
        width="640px"
        height="480px"
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8iQRxswqzoNBhOtj2zKk8wBUMVZHRTA0NU9PSExLSzVCN1pDWTFNRjI0Ui4u&embed=true%22"
        style={{
          border: "none",
          maxWidth: "100%",
          maxHeight: "100vh"
        }}
        allowFullScreen="true"
        webkitAllowFullScreen="true"
        mozAllowFullScreen="true"
        msAllowFullScreen="true"
      >
      </iframe>
    </FormContainer>
  )
}