import React from 'react';

function Contact(props) {
	return (
		<div>
			<h2>Contact US</h2>
		</div>
	);
}

export default Contact;