import Footer from "./footer/Footer";
import Hero from "./hero/Hero";
import Navbar from "./navbar/Navbar";
import Posts from "./posts/Posts";

export default function BlogHome() {
	return (
		<>
			<Navbar />
			<Hero />
			<Posts />
			<Footer />
		</>
	)
}