import React from 'react';

function PopularTopics(props) {
	return (
		<>
			<h4 className="fw-bolder mt-3 mb-4">Popular Topics</h4>
			<div className="topics">
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Tech</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Music</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Lifestyle</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Design</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Programming</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Nature</a>
				<a href="" className="btn btn-dark no-border-radius me-2 mb-3">Animal</a>
				<a href="" className="btn btn-outline-dark no-border-radius me-2 mb-3">See All
					Topics</a>
			</div>
		</>
	);
}

export default PopularTopics;