import { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Left from './components/Left';
import Right from './components/Right';

const Section = styled.section`

`;

const SignIn = () => {

  const [title, setTitle] = useState("Sign In | Eunit");

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);




  return (
    <Section
      className="sign-in-content"
      id="sign-in-content"
      style={{
        margin: "0",
        padding: "0",
      }}
    >
      <Container
        style={{
          margin: "0",
          padding: "0",
          minWidth: "100%",
        }}
      >
        <Row
          style={{
            padding: "0",
            minHeight: "100vh",
            width: "100%",
            margin: "auto",
          }}
        >

          <Col
            xs={{ span: 6, order: 2 }}
            md={{ span: 6, order: 1 }}
            lg={{ span: 6, order: 1 }}
            style={{
              padding: "0"
            }}
            className="d-none d-lg-block"
          >
            <Left />

          </Col>


          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 6, order: 2 }}
            style={{}}
            className="bg-white"
          >

            <Right
            />
          </Col>


        </Row>
      </Container>
    </Section>
  );
}

export default SignIn;