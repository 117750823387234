import React from 'react';
import Post from '../popularPosts/Post';

function PopularPosts(props) {
	return (
		<>
			<h4 className="fw-bolder mb-4">Popular Posts</h4>
			<div className="row">
				<div className="col-md-6 col-lg-12">
					<Post />
					<Post />
				</div>
			</div>

		</>
	);
}

export default PopularPosts;