import { useExport}  from '../../../../hooks/useExport';
import { useIcons }  from '../../../../hooks/useIcons';
import ReactTooltip from 'react-tooltip';

function Text() {
  const {
    HTML5,
    CSS3,
    JS,
    TS,
    JQuery,
    BS,
    React,
    Git,
    GitHub,
    SASS,
    Tailwind,
    Python,
    Firebase,
    Flask,
    Wordpress,
    VSCode,
    NodeJS,
    VueJS,
    Postgresql,
    Heroku,
    Squarespace,
    Trello
  } = useIcons();

  const skillSetHeading = {
    // margin: "12px 0 0 0"
  };

  const { testimonialImg: eunitImg } = useExport();

  return (
    <>
      <div className="col-md-6">
        <div className="row">
          <div className="col-sm-6 col-md-5">
            <div
              className="about-img"
              data-aos="fade-up"
            >
              <img src={eunitImg} className="img-fluid b-shadow-a rounded" alt="Emmanuel Uchenna" />
            </div>
          </div>
          <div className="col-sm-6 col-md-7">
            <div
              className="about-info"
              data-aos="fade-up"
            >
              <p>
                <span className="title-s">Name: </span> <span>Emmanuel Uchenna</span>
              </p>
              <p>
                <span className="title-s">Bio: </span> <span>Software developer and technical writer</span>
              </p>
              <p>
                <span className="title-s">Email: </span>
                <span>
                  <a
                    title="Mail Eunit"
                    href="mailto:eunithelp@gmail.com">eunithelp@gmail.com
                  </a>
                </span>
              </p>
              <p>
                <span className="title-s">Phone: </span> <span><a href="tel:+2348110863115">(+234)
                  811-0863-115</a></span>
              </p>
            </div>
          </div>
        </div>

        {/* <!-- Skills area --> */}
        <div className="title-s mb-3 mt-3">
          <span
            className="float-left pull-left"
            data-aos="fade-up"
          >SKILL SET</span>
        </div>

        <div className="icons-container">
          <p
            style={{ skillSetHeading }}
            className="skill-set-heading"
            data-aos="fade-up"

          >Frontend</p>
          <HTML5
            data-tip="HTML5"
            data-aos="fade-up"
            className="cursor"
          />
          <CSS3
            data-tip="CSS3"
            data-aos="fade-up"
            className="cursor"
          />
          <JS
            data-tip="JavaScript"
            data-aos="fade-up"
            className="cursor"
          />
          <TS
            data-tip="TypeScript"
            data-aos="fade-up"
            className="cursor"
          />
          <JQuery
            data-tip="jQuery"
            data-aos="fade-up"
            className="cursor"
          />
          <BS
            data-tip="Bootstrap"
            data-aos="fade-up"
            className="cursor"
          />
          <React
            data-tip="ReactJS"
            data-aos="fade-up"
            className="cursor"
          />
          <SASS
            data-tip="SASS"
            data-aos="fade-up"
            className="cursor"
          />
          <Tailwind
            data-tip="TailwindCSS"
            data-aos="fade-up"
            className="cursor"
          />
          <VueJS
            data-tip="VueJS"
            data-aos="fade-up"
            className="cursor"
          />
        </div>

        <div className="icons-container">
          <p
            style={{ skillSetHeading }}
            className="skill-set-heading"
            data-aos="fade-up"

          >Backend</p>

          <NodeJS
            data-tip="NodeJS"
            data-aos="fade-up"
            className="cursor"
          />
          <Postgresql
            data-tip="PostgreSQL"
            data-aos="fade-up"
            className="cursor"
          />
          <Firebase
            data-tip="Firebase"
            data-aos="fade-up"
            className="cursor"
          />
          <Heroku
            data-tip="Heroku"
            data-aos="fade-up"
            className="cursor"
          />
          <Flask
            data-tip="Flask"
            data-aos="fade-up"
            className="cursor"
          />
          <Python
            data-tip="Python"
            data-aos="fade-up"
            className="cursor"
          />
        </div>

        <div className="icons-container">
          <p
            style={{ skillSetHeading }}
            className="skill-set-heading"
            data-aos="fade-up"
          >
            Content Management Systems</p>

          <Wordpress
            data-tip="WordPress"
            data-aos="fade-up"
            className="cursor"
          />
          <Squarespace
            data-tip="SquareSpace"
            data-aos="fade-up"
            className="cursor"
          />
        </div>


        <div className="icons-container">
          <p
            style={{ skillSetHeading }}
            className="skill-set-heading"
            data-aos="fade-up"
          >
            Development Tools</p>

          <Git
            data-tip="Git"
            data-aos="fade-up"
            className="cursor"
          />
          <GitHub
            data-tip="GitHub"
            data-aos="fade-up"
            className="cursor"
          />
          <Trello
            data-tip="Trello"
            data-aos="fade-up"
            className="cursor"
          />
          <VSCode
            data-tip="Visual Studio Code"
            data-aos="fade-up"
            className="cursor"
          />
        </div>

        <ReactTooltip
          effect="solid"
          type="dark"
          place="top"
          event="mouseover"
          eventOff="mouseleave"
          offset={{ 'top': 5 }}
          multiline={true}
          data-aos="fade-up"
          className="cursor"
          delayHide={300}
          delayShow={300}
          textColor="#ffffff"
          backgroundColor="#000000"
          scrollHide={false}
          clickable={true}
        />
      </div>
    </>
  );
}

export default Text;