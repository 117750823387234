import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useCreatedAt } from '../../../../../../hooks/useCreatedAt';
import { useTimeToRead } from '../../../../../../hooks/useTimeToRead';
import useAnalyticsEventTracker from '../../../../../../hooks/useAnalyticsEventTracker';
import { Colors } from '../../../../../../hooks/useColors';
import SubTitle from '../../../../../eunitblog/utils/subtitle/SubTitle';
import authService from '../../../../../../services/auth.service';
import { useExport } from '../../../../../../hooks/useExport';








const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


const CardComponent = styled.div`
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 0;
    display: block;
    border: 1px solid #eee;
    border-radius: 6px;
    min-height: 415px;
    height: 415px;
    margin: 1rem auto;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const BlogImage = styled.div`
  min-height: 200px;
  max-height: 200px;
  min-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const BlogContent = styled.div`
  min-height: 150px;
  max-height: 150px;
  min-width: 100%;
  padding: 1rem;
`;

const DateAndTime = styled.div`
    font-size: small;
    font-weight: 500;
    margin-top: 1rem;
`;

const Separator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Div = styled.div`

`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
`;




export default function CardContent({
  blogTitle,
  blogpostImage,
  timeToRead,
  date,
  id,
  blogBody,
  slug
}) {

  const gaEventTracker = useAnalyticsEventTracker('Blog cards');
  const isAuthenticated = authService.isAuthenticated();

  const {
    fourthColor,
    secondaryColor
  } = Colors();


  const { editIcon } = useExport();

  return (
    <CardComponent>

      <a
        href={`/${slug}/${id}`}
      >
        <BlogImage
          style={{
            backgroundImage: `url(${blogpostImage})`,
          }}
        >

        </BlogImage>
      </a>

      <BlogContent>

        <a
          href={`/${slug}/${id}`}
          onClick={() => gaEventTracker(`${blogTitle}`)}
        >
          <SubTitle
            cName="clamped-text"
            textColor={fourthColor}
            text={blogTitle}
            textAlign="left"
            style={{
              fontWeight: "bold",
              margin: "0"
            }}
          />
        </a>

        <Wrapper>
          <DateAndTime
            style={{
              color: fourthColor
            }}
          >

            <Separator>
              <Div>
                {useCreatedAt(date)} &middot; {useTimeToRead(blogBody)} min read
              </Div>

              <Div>
                {isAuthenticated &&
                  <Link
                    to={`/admin/edit/${slug}/${id}`}
                    style={{
                      color: secondaryColor,
                      textDecoration: 'none'
                    }}
                  >
                    Edit

                    <Img
                      src={editIcon}
                      alt="Edit"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Link>
                }
              </Div>

            </Separator>
          </DateAndTime>

          <a
            href={`/${slug}/${id}`}
          >
            {/* <ReadMore /> */}
          </a>
        </Wrapper>
      </BlogContent>


    </CardComponent>
  )
}