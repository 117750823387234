
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import BlogTextArea from "../../../blog/components/blog-textarea/BlogTextArea";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../../../navbar/Navbar";
import BlogTools from "../../../blog/components/blog-tools/BlogTools";
import { useFetch } from "../../../../../hooks/useFetch";
import { constants } from "../../../../../constants/constants";
import authHeader from "../../../../../services/auth-header";







export default function PostComponents() {



  const API_URL = constants.API_URL;
  const headers = authHeader();

  const [author, setAuthor] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const current = new Date();
  const autoDate = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}-${current.getDate()}`;
  const [date, setDate] = useState(autoDate);
  const [isLoading, setIsLoading] = useState(false);
  const [timeToRead, setTimeToRead] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogBody, setBlogBody] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [postError, setPostError] = useState("");
  const [locationPathName, setLocationPathName] = useState("");
  const [title, setTitle] = useState("Eunit Blog | Admin");
  const [article, setArticle] = useState({});
  const [blog_API_URL, set_blog_API_URL] = useState("");
  const pathname = location.pathname;
  const [isWriting, setIsWriting] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const pathArray = window.location.pathname.split('/');
  const slug = pathArray[3];


  const {
    isPending, data
  } = useFetch(blog_API_URL, {});






  // Fetch blog details depending on Write or Edit

  useEffect(() => {

    // Writing blog

    if (pathname === `/admin/write`) {
      setTitle("Write Blog | Eunit Blog");
      setLocationPathName(pathname);
      set_blog_API_URL(`${API_URL}/blogs`);
      setIsWriting(true);

    }

    // Editing blog post
    else if (pathname === `/admin/edit/blog/${id}`) {
      setLocationPathName(pathname);
      set_blog_API_URL(`${API_URL}/blogs/${id}`);




      if (!isPending) {


        setArticle(data.data);


        // Set blog data back if Editing

        setAuthor(article.author);
        setBlogBody(article.blogBody);
        setBlogTitle(article.blogTitle);
        setFeaturedImage(article.featuredImage);
        setDate(JSON.stringify(article.createdAt));


        setTitle(`${article?.blogTitle ? article?.blogTitle : "Edit Blog"} | Eunit Blog`);

      }
    }

    // This points to drafts
    else {
      setLocationPathName(pathname);
      set_blog_API_URL(`${API_URL}/drafts/${id}`);
      // console.log(`blog_API_URL: ${blog_API_URL}`);

      setIsDraft(true);
      // console.log(`isDraft: ${isDraft}`);

      if (!isPending) {

        setArticle(data.data);


        // Set blog data back if Editing

        setAuthor(article.author);
        setBlogBody(article.blogBody);
        setBlogTitle(article.blogTitle);
        setFeaturedImage(article.featuredImage);
        setDate(JSON.stringify(article.createdAt));

        setTitle(`${article?.blogTitle ? article?.blogTitle : "Edit Draft"} | Eunit Blog`);

      }
    };
  },
    [API_URL, article, article.author, article.blogTitle, blog_API_URL, data.data, id, isDraft, isPending, pathname, slug]);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);





  const handleUpdate = (e) => {

    e.preventDefault();
    setIsLoading(true);

    const newBlogPost = { author, featuredImage, date, blogTitle, blogBody };





    if (newBlogPost.author === "") {
      toast.error(`Please fill out the author field!`);
      setIsLoading(false);
    } else if (newBlogPost.featuredImage === "") {
      toast.error(`Please fill out the featured image field!`);
      setIsLoading(false);
    } else if (newBlogPost.date === "") {
      toast.error(`Please fill out the date field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogTitle === "") {
      toast.error(`Please fill out the blog title field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogBody === "") {
      toast.error(`Please fill out the blogBody field!`);
      setIsLoading(false);
    } else {
      toast.info(`Updating post. Hang in tight...`);
    }




    let formData = new FormData();

    formData.append("author", newBlogPost.author);
    formData.append("featuredImage", newBlogPost.featuredImage);
    formData.append("date", newBlogPost.date);
    formData.append("blogTitle", newBlogPost.blogTitle);
    formData.append("blogBody", newBlogPost.blogBody);






    axios.put(blog_API_URL, formData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Post updated successfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/${slug}/${id}`);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong!");
        console.error(err?.message);
        setPostError(err?.response?.data.error);
        setIsLoading(false);
      });

  };




  // This concerns ONLY drafts
  const handlePreview = (e) => {

    e.preventDefault();
    setIsLoading(true);

    const newBlogPost = { author, featuredImage, date, blogTitle, blogBody };


    if (newBlogPost.author === "") {
      toast.error(`Please fill out the author field!`);
      setIsLoading(false);
    } else if (newBlogPost.featuredImage === "") {
      toast.error(`Please fill out the featured image field!`);
      setIsLoading(false);
    } else if (newBlogPost.date === "") {
      toast.error(`Please fill out the date field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogTitle === "") {
      toast.error(`Please fill out the blog title field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogBody === "") {
      toast.error(`Please fill out the blogBody field!`);
      setIsLoading(false);
    } else {
      toast.info(`Creating draft. Hang in tight...`);
    }




    let formData = new FormData();

    formData.append("author", newBlogPost.author);
    formData.append("featuredImage", newBlogPost.featuredImage);
    formData.append("date", newBlogPost.date);
    formData.append("blogTitle", newBlogPost.blogTitle);
    formData.append("blogBody", newBlogPost.blogBody);


    axios.post(`${API_URL}/drafts`, formData, { headers })
      .then((res) => {

        if (res?.status === 200) {
          toast.success("Draft created successfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/draft`);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.error(err);
        console.error(err?.message);
        setPostError(err?.response?.data.error);
        setIsLoading(false);
      });

  };





  // This concerns ONLY blogs
  const handlePublish = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newBlogPost = {
      author,
      featuredImage,
      date,
      blogTitle,
      blogBody
    };


    if (newBlogPost.author === "") {
      toast.error(`Please fill out the author field!`);
      setIsLoading(false);
    } else if (newBlogPost.featuredImage === "") {
      toast.error(`Please fill out the featured image field!`);
      setIsLoading(false);
    } else if (newBlogPost.date === "") {
      toast.error(`Please fill out the date field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogTitle === "") {
      toast.error(`Please fill out the blog title field!`);
      setIsLoading(false);
    } else if (newBlogPost.blogBody === "") {
      toast.error(`Please fill out the blogBody field!`);
      setIsLoading(false);
    } else {
      toast.info(`Publishing post. Hang in tight...`);
    }


    let formData = new FormData();


    formData.append("author", newBlogPost.author);
    formData.append("featuredImage", newBlogPost.featuredImage);
    formData.append("date", newBlogPost.date);
    formData.append("blogTitle", newBlogPost.blogTitle);
    formData.append("blogBody", newBlogPost.blogBody);


    axios.post(isDraft ? `${API_URL}/blogs` : blog_API_URL, formData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Post created successfully!");
          setIsLoading(false);
          setTimeout(() => {
            navigate(`/blog`);
            // eslint-disable-next-line no-restricted-globals
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        console.error(err);
        console.error(err?.message);
        setPostError(err?.response?.data.error);
        setIsLoading(false);
      });

  };






  return (
    <>
      <Navbar />

      <Fragment

      >
        <BlogTools
          author={isWriting ? author : article.author}
          featuredImage={isWriting ? featuredImage : article.featuredImage}
          id={isWriting ? id : article.id}
          date={isWriting ? date : article.date}
          timeToRead={isWriting ? timeToRead : article.timeToRead}

          isLoading={isLoading}
          setAuthor={setAuthor}
          setFeaturedImage={setFeaturedImage}
          setDate={setDate}
          setTimeToRead={setTimeToRead}

          handlePublish={handlePublish}
          handlePreview={handlePreview}
          locationPathName={locationPathName}
          isWriting={isWriting}
          handleUpdate={handleUpdate}
          postError={postError}
          slug={slug}
        />

        <BlogTextArea
          blogTitle={isWriting ? blogTitle : article.blogTitle}
          setBlogTitle={setBlogTitle}

          blogBody={isWriting ? blogBody : article.blogBody}
          setBlogBody={setBlogBody}
        />

      </Fragment>
    </>
  )
}