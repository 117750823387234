import React from 'react';
import './Portfolio.css';
import AboutSection from './aboutsection/AboutSection';
import PortfolioHero from './portfoliohero/PortfolioHero';
import PortfolioNav from './portfolionav/PortfolioNav';
import Services from './servicessection/Services';
import PortfolioWork from './portfoliowork/PortfolioWork';
import PortfolioArticles from './portfolioarticles/PortfolioArticles';
import Testimonial from './testimonial/Testimonial';
import Contact from './contact';
import Footer from './footer';

















function Portfolio(props) {
	return (
		<>
			<PortfolioNav />
			<PortfolioHero />
			<AboutSection />
			<Services />
      <PortfolioWork />
      <PortfolioArticles />
      <Testimonial />
      <Contact />
      <Footer />
		</>
	);
}

export default Portfolio;