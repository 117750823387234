import React from 'react';
import './Hero.css';


function Hero(props) {
  return (
    <>
      <section className="hero d-flex align-items-center mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="fw-bolder">
                <span className="brand-e">E</span>
                <span className="brand-text">unit Blog</span>
                &nbsp;is a place to write, read and connect
              </h1>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Mollitia nesciunt repellendus sapiente
                qui doloremque quae.</p>
              <a href="/write" className="btn btn-outline-dark no-border-radius">Start Writing</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;