import { Fragment } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Card from './components/Card';
import { useFetch } from "../../../../../hooks/useFetch";
import { constants } from "../../../../../constants/constants";
import Loader from "../../../../eunitblog/utils/loader/Loader";





export default function BlogsCard({ slug }) {




  const API_URL = constants.API_URL;

  const {
    isPending, error, data
  } = useFetch(`${API_URL}/${slug}s`, {});

  if (error) {
    console.error(error);
  }

  const blogs = data.data;


  return (
    <>
      <section
        className="section blog-cards-section"
        id="blog-cards"
        style={{
          position: "relative",
          top: "-45vh"
        }}
      >

        <Container>

          <Row
            className="mt-5"
          >
            {isPending ?

              (
                <Fragment>
                  <Loader />
                </Fragment>
              ) :

              <Fragment>
                {blogs.map((blog, index) => (
                  <Col
                    xs={{ span: 12 }}
                    md={{ span: 6 }}
                    lg={{ span: 4 }}
                    key={blog.id}
                  >
                    <Card
                      blogTitle={blog.blogTitle}
                      blogBody={blog.blogBody}
                      date={blog.date}
                      timeToRead={blog.timeToRead}
                      blogpostImage={blog.featuredImage}
                      id={blog.id}
                      slug={slug}
                    />

                  </Col>
                ))}
              </Fragment>
            }

          </Row>

        </Container>
      </section>
    </>
  )
}