import { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BlogsCard from '../../../blog/components/blogs-cards';
import { Link, useLocation } from "react-router-dom";
import { useLocationPathName } from "./../../../../../hooks/useLocationPathname";
import { constants } from '../../../../../constants/constants';
import { useFetch } from "./../../../../../hooks/useFetch";
import Navbar from "./../../../navbar/Navbar";
import Footer from '../../../footer/Footer';
import Loader from '../../../utils/loader/Loader';





export default function AdminDraftComponent({ slug }) {

  const location = useLocation();

  const result = useLocationPathName(location);

  const [title, setTitle] = useState("Eunit | Draft");


  const URL = constants.API_URL;

  const {
    isPending,
    error,
    data
  } = useFetch(`${URL}/drafts`, {});

  if (error) {
    console.error(error);
  }

  const drafts = data.data;

  useEffect(() => {

    if (!isPending) {

      setTitle(`Eunit | Drafts`);

    }
  }, [isPending]);


  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  return (
    <>
      <Navbar />

      <Container>

        <p
          className="heading"
        >
          {result}
        </p>

        <Row
          className="mt-5"
        >

          {isPending ? (<Fragment><Loader /> </Fragment>) :
            <Fragment>

              {drafts.map((blog, index) => (


                <Col
                  xs={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 4 }}
                  key={blog.id}
                >
                  <Link
                    to={`/draft/${blog.id}`}
                  >
                    <BlogsCard
                      blogTitle={blog.blogTitle}
                      blogBody={blog.blogBody}
                      date={blog.date}
                      timeToRead={blog.timeToRead}
                      blogpostImage={blog.featuredImage}
                      id={blog.id}
                      slug="draft"
                    />
                  </Link>

                </Col>

              ))}

            </Fragment>

          }
        </Row>

      </Container>

      <Footer />
    </>
  )
}