import axios from "axios";
import { toast } from "react-toastify";
import authHeader from "./auth-header";
import { adminRedirection, superAdminRedirection, inactiveUserAction } from "../reducers";
import { constants } from "./../constants/constants";





export const API_URL = constants.API_URL;

const headers = authHeader();

const signup = async (email, password, role, userName, name) => {

  const response = await axios
    .post(API_URL + "/auth",
      {
        "email": email,
        "password": password,
        "role": role,
        userName,
        name,
        "status": "inactive"
      },
      {
        "headers": headers
      }
    );

  if (response?.data?.token) {
    toast.success("Account successfully created.");
  }
  return response?.data;
};

const login = async (email, password) => {
  const response = await axios
    .post(API_URL + "/auth/login",
      {
        "email": email,
        "password": password,
      }
    );

    console.log(response)
  if (response?.data?.user?.role === "admin" && response?.data?.user?.status === "active") {
    adminRedirection();
  };

  if (response?.data?.user?.role === "super_admin" && response?.data?.user?.status === "active") {
    superAdminRedirection();
  };

  // User is set to Inactive by admin
  if (response?.data?.user?.status === "inactive") {
    inactiveUserAction();
  }

  // Something is not right.
  if (response?.data?.user?.role !== "" && response?.data?.user?.status === "") {
    toast.error("Something is not right.");
  };

  if (response?.data?.token && response?.data?.user?.status === "active") {
    localStorage.setItem("user", JSON.stringify(response?.data?.user));
    localStorage.setItem("token", JSON.stringify(response?.data?.token));


    localStorage.setItem("isAuthenticated", "true");
    toast.success("Logged in successful");
  };

};

const resetPassword = async (password, userId, authToken) => {

  const response = await axios
    .post(`${API_URL}/auth/reset-password/${userId}/${authToken}`,
      {
        "password": password,
      },
    );

  if (response.status === 200 || response.readyState
    === 4) {
    toast.success("Password reset successfully!");
  } else {
    toast.error("Something went wrong!");
  }
};

const forgotPassword = async (email) => {
  const response = await axios
    .post(API_URL + "/auth/forgot-password",
      {
        "email": email,
      },
      {
        "headers": headers
      }
    );

  if (response.status === 200 || response.readyState
    === 4) {
    toast.success("Email sent successfully!");

    // Clear localStorage details
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
  } else {
    toast.error("Something went wrong!");
  }
};

const isAuthenticated = () => {
  return JSON.parse(localStorage.getItem("isAuthenticated"));
};

const currentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};


const logout = async () => {

  const response = await axios
    .get(API_URL + "/auth/logout",

    );

  localStorage.removeItem("user");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("token");
  window.location.href = "/auth";
  toast.success("Logged out successful");
  console.log(response)
};

const authService = {
  signup,
  login,
  logout,
  resetPassword,
  forgotPassword,
  isAuthenticated,
  currentUser
};

export default authService;