import React from 'react';
import Article from './article/Article';
import './Article.css';
import ArticleHero from './article-hero/ArticleHero';

function Articles(props) {
	return (
		<>
			<div className="col-md col-lg-8 mb-4">
				<div className="row">
					<ArticleHero />
					<Article />
					<Article />
					<Article />
					<Article />
					<Article />
					<Article />
				</div>
			</div>
		</>
	);
}

export default Articles;