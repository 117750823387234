import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';


function Navbar(props) {
	return (
		<>
			{/* <!-- Navbar --> */}
			<nav className="navbar navbar-expand-lg navbar-light bg-white py-3 fixed-top">
				<div className="container">
					<a className="navbar-brand fw-bolder fs-3" href="/">
						<span className="brand-e">E</span>
						<span className="brand-text">unit Blog</span>
					</a>
					<button className="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
						aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav ms-auto">

							<li className="nav-item">
								<NavLink  className="nav-link blog-nav" to="/">Home</NavLink>
							</li>
							<li className="nav-item">
								<NavLink  className="nav-link blog-nav" to="/about">About</NavLink>
							</li>
							<li className="nav-item">
								<NavLink  className="nav-link blog-nav" to="/contact">Contact</NavLink>
							</li>
							<li className="nav-item">
								<NavLink  className="nav-link blog-nav" to="/auth/sign-in">Sign In</NavLink>
							</li>
						</ul>
						<form className="d-lg-none d-flex">
							<input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
							<button className="btn btn-dark" type="submit"><i className="bi bi-search"></i></button>
						</form>
					</div>
				</div>
			</nav>
			{/* <!-- End of Navbar --> */}
		</>
	);
}

export default Navbar;