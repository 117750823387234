import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import authService from '../../../../../services/auth.service';
import { useIcons } from '../../../../../hooks/useIcons';
import Select from 'react-select';
import { Colors } from '../../../../../hooks/useColors';
import ButtonComponent from "./../../../../eunitblog/utils/button/ButtonComponent";
import CloseButton from '../../../../eunitblog/utils/button/closebutton';






const {
  primaryColor,
  whiteColor,
  lightGrayColor
} = Colors();



const Wrapper = styled.div`

  display: block;
  align-items: center;
  gap: 30px;
  width: 600px;
  background: ${whiteColor};
  border-radius: 6px;
  margin: auto 1rem;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  width: 100%;
  padding: 1.235rem;
  color: #212429;
  border-bottom: 0.5px solid ${lightGrayColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;








const FormContainer = styled.div`
  width: 90%;
  margin: auto auto;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    ${'' /* width: 70%; */}
  }
`;

const FormContent = styled.div`
`;






export default function CreateAdminForm({
  setShowOverlay
}) {



  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otherError, setOtherError] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { register, formState: { errors }, handleSubmit } = useForm();

  const options = [
    { value: "admin", label: "Admin" },
    { value: "super_admin", label: "Super admin" },
  ];


  const handleRole = (selectedRole) => {
    setSelectedRole(selectedRole);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await authService.signup(data.email, data.password, selectedRole.value, data.userName, data.name).then(
        () => {
          setShowOverlay(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        (error) => {
          console.error(error);
          setOtherError(error?.message);
          setAuthError(error?.response?.data?.error);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);

  const {
    FaRegEye,
    FaRegEyeSlash,
  } = useIcons();

  return (

    <>
      <Wrapper
      >
        <Header>
          <div>
            Add new admin account
          </div>

          <CloseButton
            handleClick={(e) => setShowOverlay(false)}
          />

        </Header>

        <FormContent>
          <FormContainer
            style={{}}
          >
            <FormContent
              style={{
                backgroundColor: whiteColor,
                width: "100%",
                padding: "1rem",
                paddingTop: "0"
              }}
            >


              <Form>
                <Form.Group>

                  <Container>

                    <Row>
                      <Col>
                        <Form.Label
                          style={{
                            margin: "12px auto",
                            color: lightGrayColor,
                          }}
                        >
                          Username
                        </Form.Label>
                        <Form.Control
                          className={`${errors?.userName ? 'input-error' : ''}`}
                          name="userName"
                          {...register("userName", { required: "Please enter your username" })}
                          type="text"
                          autoComplete="off"
                          placeholder="Enter username"
                          style={{
                            backgroundColor: whiteColor,
                            padding: "12px 28px",
                          }}
                        />

                        {errors?.userName && (
                          <p className="errorMsg">{errors?.username?.message}</p>
                        )}
                      </Col>

                      <Col>
                        <Form.Label
                          style={{
                            margin: "12px auto",
                            color: lightGrayColor,
                          }}
                        >
                          Full name
                        </Form.Label>

                        <Form.Control
                          className={`${errors?.name ? 'input-error' : ''}`}
                          name="name"
                          {...register("name", { required: "Please enter your name" })}
                          type="text"
                          autoComplete="off"
                          placeholder="Enter full name"
                          style={{
                            backgroundColor: whiteColor,
                            padding: "12px 28px",
                          }}
                        />

                        {errors?.name && (
                          <p className="errorMsg">{errors?.name?.message}</p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Label
                          style={{
                            margin: "12px auto",
                            color: lightGrayColor,
                          }}
                        >
                          Email address
                        </Form.Label>

                        <Form.Control
                          className={`${errors?.email ? 'input-error' : ''}`}
                          name="email"
                          {...register("email", { required: "Please enter email" })}
                          type="text"
                          autoComplete="off"
                          placeholder="Enter email"
                          style={{
                            backgroundColor: whiteColor,
                            padding: "12px 28px",
                          }}
                        />

                        {errors?.email && (
                          <p className="errorMsg">{errors?.email.message}</p>
                        )}

                      </Col>

                      <Col>
                        <Form.Label
                          style={{
                            margin: "12px auto",
                            color: lightGrayColor,
                          }}
                        >
                          Password
                        </Form.Label>
                        <Form.Control
                          className={`${errors?.password ? 'input-error' : ''}`}
                          name="password"
                          {...register("password", { required: "Please input password" })}
                          type={seePassword ? 'text' : 'password'}
                          autoComplete="off"
                          placeholder="Enter password"
                          style={{
                            backgroundColor: whiteColor,
                            padding: "12px 28px",
                          }}
                        />

                        {seePassword ?
                          <FaRegEyeSlash
                            className="auth-icon"
                            onClick={() => setSeePassword(false)}
                          />
                          :
                          <FaRegEye
                            className="auth-icon"
                            onClick={() => setSeePassword(true)}
                          />}

                        {errors?.password && (
                          <p className="errorMsg">{errors?.password.message}</p>
                        )}
                      </Col>
                    </Row>
                  </Container>

                  <Row>

                    <Col>
                      <Form.Label
                        style={{
                          margin: "12px auto",
                          color: lightGrayColor,
                        }}
                      >
                        Role
                      </Form.Label>


                      <Select
                        options={options}
                        name="role"
                        type="text"
                        autoComplete="off"
                        placeholder="Select role"
                        style={{
                          backgroundColor: whiteColor,
                          padding: "12px 28px",
                        }}
                        onChange={handleRole}
                      />
                    </Col>
                  </Row>

                </Form.Group>
              </Form>


              {authError &&
                (<p
                  className="errorMsg"
                >
                  Error occurred: {" "}
                  {authError}
                </p>
                )
              }

              {otherError &&
                (<p
                  className="errorMsg"
                >
                  Error occurred: {" "}
                  {otherError}
                </p>
                )
              }



              <ButtonComponent
                bgColor={primaryColor}
                text={!isLoading ? "Create admin" : "Creating admin ..."}
                style={{
                  minWidth: "100%",
                  margin: "2.125rem auto 1rem auto",
                }}
                click={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />

            </FormContent>
          </FormContainer>
        </FormContent>
      </Wrapper>
    </>
  )
}