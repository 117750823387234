import ContactDetails from './components/ContactDetails';
import Form from './components/Form';
import './Contact.css';





export default function Contact() {

  return (
    <>
      <div
        className="container"
        data-aos="fade-up"
      >
        <div className="row">
          <div className={`col-sm-12`}>
            <div className="contact-mf">
              <div id="contact" className="box-shadow-full">
                <div className="row">

                  <div className="col-md-6">
                    <Form />
                  </div>

                  <div className="col-md-6">
                    <ContactDetails />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}