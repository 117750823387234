import React from 'react';

function Blog(props) {
	return (
		<>
			<div className="col-lg-8">
				<h1 className="fw-bolder mb-4">17 Inspiring Examples of Beautiful Blog Homepage Design</h1>
				<div className="d-flex align-items-center mb-4">
					<img src="/assets/img/avatar-1.jpg" alt="John Doe" className="img-writer rounded-circle me-3" />
					<div className="flex-column">
						<a href="" className="d-block text-dark">John Doe</a>
						<small className="text-secondary">June 26, 2021 &bullet; <span id="read-time"></span> min
							read &bullet; <a href="">Design</a></small>
					</div>
				</div>
				<img src="/assets/img/post-1.jpg" alt="Post 1" className="rounded-3 mb-4 img-post" />
				<div id="content" className="mb-4">
					<p>I loved those first tours. Bouncing off a sweaty stage in an Edinburgh catacomb we then had
						to get to a gig in Camden by lunch the next day. We couldn’t fit all four of us and Ted’s
						double-bass into the VW Polo. I think it was Ben who drew the short-straw and had to follow
						by train with his keyboard. I remember blitzing it down the M6 through the night, the lads
						asleep beside me. We made it but my voice sadly didn’t, completely shot by exhaustion, I had
						to mime my harmonies. Being in Mumford & Sons was exhilarating.
						Every gig was its own adventure. Every gig its own story. Be it odysseys through the
						Scottish Islands, or soapbox shows in Soho. Where would we sleep that night? Hostels in Fort
						William, pub floors in Ipswich, even the Travelodge in Carlisle maintains a sort of charm in
						my mind. We saw the country and then, as things miraculously grew, the world. All the while
						doing what we loved. Music. And not just any music. These songs meant something. They felt
						important to me. Songs with the message of hope and love. I was surrounded by three
						supremely talented song-writers and Marcus, our singer with a one-in-a-million voice. A
						voice that can compel both a field of 80,000 and the intimacy of a front room. Fast-forward
						ten years and we were playing those same songs every night in arenas, flying first-class,
						staying in luxury hotels and being paid handsomely to do so. I was a lucky boy.</p>
				</div>
				<h4 className="fw-bolder mb-3">Share</h4>
				<ul className="list-unstyled d-flex mb-5 fs-3" id="share-buttons">
					<li>
						<a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="bottom"
							title="Facebook">
							<i className="me-4 bi bi-facebook"></i>
						</a>
					</li>
					<li>
						<a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="bottom"
							title="Linkedin">
							<i className="me-4 bi bi-linkedin"></i>
						</a>
					</li>
					<li>
						<a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="bottom"
							title="Whatsapp">
							<i className="me-4 bi bi-whatsapp"></i>
						</a>
					</li>
					<li>
						<a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="bottom"
							title="Twitter">
							<i className="me-4 bi bi-twitter"></i>
						</a>
					</li>
					<li>
						<a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="bottom"
							title="Copy to Clipboard">
							<i className="me-4 bi bi-clipboard"></i>
						</a>
					</li>
				</ul>
				{/* <!-- Comment Form (If user was login) --> */}
				<form action="" className="mb-4">
					<div className="mb-3">
						<label className="form-label" htmlFor="comment">Comment</label>
						<textarea name="" className="form-control" id="comment" cols="30" rows="3"></textarea>
					</div>
					<button type="submit" className="btn btn-outline-dark">Submit</button>
				</form>
				{/* <!-- End of Comment Form --> */}
				<a href="" className="btn btn-outline-dark no-border-radius mb-4 d-block">You must login first to
					comment</a>
				{/* <!-- Comments --> */}
				<div className="comments mb-5">
					<h4 className="fw-bolder mb-4">4 Comments</h4>
					<div className="d-flex mb-4">
						<img src="/assets/img/avatar-2.jpg" alt="Alexa Jeffrey"
							className="rounded-circle me-3 img-commentator" />
						<div className="flex-column">
							<span className="d-block">
								<a href="" className="text-dark">Alexa Jeffrey</a> &bullet; 1 days ago
							</span>
							<small className="text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing
								elit.</small>
							<a href="" className="d-block">
								<small>Reply</small>
							</a>
						</div>
					</div>
					<div className="d-flex mb-4">
						<img src="/assets/img/avatar-3.jpg" alt="Johnny Alexander"
							className="rounded-circle me-3 img-commentator" />
						<div className="flex-column">
							<span className="d-block">
								<a href="" className="text-dark">Johnny Alexander</a> &bullet; 2 days ago
							</span>
							<small className="text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing
								elit.</small>
							<a href="" className="d-block">
								<small>Reply</small>
							</a>
						</div>
					</div>
					<div className="d-flex replier mb-4">
						<img src="/assets/img/avatar-4.jpg" alt="Christine Gibson"
							className="rounded-circle me-3 img-commentator" />
						<div className="flex-column">
							<span className="d-block">
								<a href="" className="text-dark">Christine Gibson</a> <i
									className="bi bi-patch-check-fill text-primary"></i>
								&bullet; 2 days
								ago
							</span>
							<small className="text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing
								elit.</small>
						</div>
					</div>
					<div className="d-flex mb-4">
						<img src="/assets/img/avatar-2.jpg" alt="Alexa Jeffrey"
							className="rounded-circle me-3 img-commentator" />
						<div className="flex-column">
							<span className="d-block">
								<a href="" className="text-dark">Alexa Jeffrey</a> &bullet; 2 days ago
							</span>
							<small className="text-secondary">Lorem ipsum dolor sit amet consectetur adipisicing
								elit.</small>
							<a href="" className="d-block">
								<small>Reply</small>
							</a>
						</div>
					</div>
				</div>
				{/* <!-- End of Comments --> */}
			</div>
		</>
	);
}

export default Blog;