import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ButtonComponent from '../../../../eunitblog/utils/button/ButtonComponent';
import { useLocation } from 'react-router-dom';
import { Colors } from '../../../../../hooks/useColors';
import authService from '../../../../../services/auth.service';







const FormContainer = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const FormContent = styled.div`

`;

export default function Right() {
  const [authError, setAuthError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useLocation();
  const pathArray = window.location.pathname.split('/');
  const userId = pathArray[3];
  const authToken = pathArray[4];
  const [otherError, setOtherError] = useState("");




  const { register, formState: { errors }, handleSubmit } = useForm();


  const onSubmit = async (data) => {
    setIsLoading(true);
    const password = data.password;

    try {
      await authService.resetPassword(password, userId, authToken).then(
        () => {
          navigate("/auth");
        },
        (error) => {
          console.error(error);
          console.error(`authError: ${error.response.data}`);
          setOtherError(error?.message);
          setAuthError(error.response.data);
          setIsLoading(false);
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    otherError && setIsLoading(false);
  }, [otherError]);


  const {
    lightGrayColor,
    whiteColor,
    primaryColor,
  } = Colors();


  return (
    <FormContainer
      style={{}}
    >
      <FormContent
        style={{
          backgroundColor: whiteColor,
          width: "100%",
          padding: "1rem",
          paddingTop: "0"
        }}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">


            <Form.Label
              style={{
                margin: "12px auto",
                color: lightGrayColor,
              }}
            >
              Password
            </Form.Label>

            <Form.Control
              className={`${errors.password ? 'input-error' : ''}`}
              name="password"
              {...register("password", { required: "Please input your password" })}
              type="password"
              autoComplete="off"
              style={{
                backgroundColor: whiteColor,
                padding: "12px 28px",
              }}
            />

            {errors.password && (
              <p className="errorMsg">{errors.password.message}</p>
            )}

          </Form.Group>


        </Form>


        {authError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {authError}
          </p>
          )
        }

        {otherError &&
          (<p
            className="errorMsg"
          >
            Error occurred: {" "}
            {otherError}
          </p>
          )
        }




        <ButtonComponent
          bgColor={primaryColor}
          text={!isLoading ? "Reset Password" : "Resetting Password ..."}
          style={{
            minWidth: "100%",
            margin: "2.125rem auto 1rem auto",
          }}
          click={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />

      </FormContent>
    </FormContainer>
  )
}