import React from 'react';

function Article(props) {
	return (
		<>
			<div className="col-md-6 col-lg-6 mb-3">
				<div className="card custom-card post h-100 p-2">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<div className="writer d-flex align-items-center mb-3">
								<img src="/assets/img/avatar-2.jpg" className="img-writer rounded-circle me-2"
									alt="Alexa Jeffrey" />
								<a href="#" className="writer-name fw-bolder">Alexa Jeffrey</a>
							</div>
							<a role="button" data-bs-toggle="tooltip" data-bs-placement="top"
								title="Save"><i className="bi bi-bookmark"></i></a>
						</div>
						<div className="img-container">
							<a href="/single">
								<img src="/assets/img/post-2.jpg" alt="Post 2" className="mb-3 rounded-3"
									height="200px" />
							</a>
							<span className="post-tag">Tech</span>
						</div>
						<a href="/single" className="fs-6 d-block text-dark fw-bolder">The Best Business
							Laptops for 2021</a>
						<small className="text-secondary">June 25, 2021  •  5 min read</small>
					</div>
				</div>
			</div>
		</>
	);
}

export default Article;