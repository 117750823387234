import React from 'react';
import SignUp from "../../../auth/sign-up";



function SignUpPage() {
  return (

    <>
      <SignUp />
    </>
  );
}

export default SignUpPage;