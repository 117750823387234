import { Form } from "react-bootstrap";
import styled from "styled-components";
import TextEditor from "../../../../utils/textEditor/TextEditor";






const BodyInputAreaContainer = styled.div`
  margin: 2rem auto 5rem;
  display: flex;
  justify-content: space-between;
`;

export default function BodyInputArea({
  blogBody,
  setBlogBody,
}) {


  return (
    <>
      <BodyInputAreaContainer>
        <Form
        >
          <Form.Group
            style={{
              minWidth: "90vw",
              maxWidth: "1024px"
            }}
            controlId="exampleForm.ControlTextarea1">
            <Form.Label
              style={{
                display: "none",
              }}
            >
              Blog text
            </Form.Label>
            <TextEditor
              setBlogBody={setBlogBody}
              blogBody={blogBody}
              name="text"
            />
          </Form.Group>
        </Form>
      </BodyInputAreaContainer>
    </>
  );
}