import introBgImg from '../assets/portfolio/img/intro-bg.jpg';
import eunit99Img from '../assets/portfolio/img/eunit99.jpg';
import eunitBlackImg from '../assets/portfolio/img/black.jpg';
import testimonialImg from '../assets/portfolio/img/testimonial-2.jpg';
import Resume from '../assets/portfolio/resume/Resume_Emmanuel_Uchenna.pdf';
import EunitLogo from '../assets/logo/logo.png';
import asideBg from '../assets/images/asideBg.jpg';
import asideLogo from '../assets/images/asideLogo.png';
import logo from '../assets/logo/logo.png';
import facebook from '../assets/icons/social/facebook.svg';
import instagram from '../assets/icons/social/instagram.svg';
import twitter from '../assets/icons/social/twitter.svg';
import linkedin from '../assets/icons/linkedin.svg';
import facebookShare from '../assets/icons/blog/facebook-black.svg';
import twitterShare from '../assets/icons/blog/twitter-black.svg';
import instagramShare from '../assets/icons/blog/instagram-black.svg';
import linkedinShare from '../assets/icons/blog/linkedin-black.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import trashIcon from '../assets/icons/trash-icon.svg';
import featuredImageIcon from '../assets/icons/feature-image-icon.svg';
import userNavbarToggler from '../assets/icons/user-nav-toggler-icon.svg';
import searchIcon from '../assets/icons/search-icon.svg';
import plusIcon from '../assets/icons/plus-icon.svg';
import deleteIcon from '../assets/icons/delete-icon.svg';














export const useExport = () => {
  return (
    {
      introBgImg,
      eunit99Img,
      eunitBlackImg,
      testimonialImg,
      Resume,
      EunitLogo,
      facebook,
      instagram,
      twitter,
      logo,
      asideBg,
      asideLogo,
      linkedin,
      facebookShare,
      twitterShare,
      instagramShare,
      linkedinShare,
      editIcon,
      trashIcon,
      featuredImageIcon,
      userNavbarToggler,
      searchIcon,
      plusIcon,
      deleteIcon
    }

  );
}
